import { userActionTypes } from '../actions/user.actions';

const INITIAL_STATE = {
    isAuthenticated: false,
    partnerLocationReference: '',
    accessToken: '',
    quickAccessUser: false,
    quoteOnlyUser: false,
    isTrackDeliveriesEnabled : false,
    isEapiUser : false
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case userActionTypes.SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.payload
            }
        case userActionTypes.SET_PARTNER_LOCATION_REFERENCE:
            return {
                ...state,
                partnerLocationReference: action.payload
            }
        case userActionTypes.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload
            }
        case userActionTypes.SET_IS_TRACK_DELIVERIES_ENABLED:
            return {
                ...state,
                isTrackDeliveriesEnabled: action.payload
            }
        case userActionTypes.SET_IS_EAPI_USER:
            return {
                ...state,
                isEapiUser: action.payload
            }
        case userActionTypes.SET_QUICK_ACCESS:
            return {
                ...state,
                quickAccessUser: action.payload
            }
        case userActionTypes.SET_QUOTE_ONLY:
            return {
                ...state,
                quoteOnlyUser: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;