import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import appConfig from '../appConfig/appConfig';
import googleSDKUtils from '../utilities/googleSDKUtils';
import { useDispatch } from "react-redux";


export const AddressTextBox = withScriptjs(withGoogleMap(props => {
    const bounds = googleSDKUtils.getBoundsFromPolylinePoints(window.google.maps, props.geofence[props.defaultGeofenceIndex]);
    const dispatch = useDispatch();
    /**
     * searchPlace()
     * This function will be called to fetch the the place (Google) based on the search query
     */
    const searchPlace = () => {
        dispatch({
            type: 'SET_SHOW_LOADER',
            payload: true
        })
        let placeName = new window.google.maps.LatLng(props.center.lat, props.center.lng)
        let mapObj = new window.google.maps.Map(document.getElementById('map'), {center: placeName});
        let service = new window.google.maps.places.PlacesService(mapObj);
        var request = {
            query: props.value,
            fields: ['ALL'],
        };
        
        service.findPlaceFromQuery(request, function(results, status) {
            if(results){
                service.getDetails({placeId: results[0].place_id}, function(res, status) {
                    props.validatePlace({address_components: res.address_components, formatted_address: res.formatted_address, geometry: res.geometry, html_attributions: res.html_attributions, place_id: res.place_id})
                })
            } else {
                props.validatePlace('')
            }
        });
    }

    return (<GoogleMap google={props.google} googleMapURL={appConfig.urls.GoogleMap_URL}>
        <Autocomplete id={props.stateType}
            onFocus={event => (event.target.autocomplete = "off")}
            className={"col form-control d-block " + (props.errors.pickupAdd ? 'invalid' : '')}
            required
            types={[]}
            onChange={(e) => {props.setAddrinState(e.target.value)}}
            onPlaceSelected={(place) => {(place && place.place_id) ? props.validatePlace(place) : searchPlace() }}
            placeholder={(props.stateType === 'pickValue') ? "Enter pickup address" : "Enter dropoff address" }
            bounds={bounds}
            // onPaste={props.disablePaste}
            onKeyDown={props.noBackspaces}
            value={props.value}
        />
        {props.value && 
            <React.Fragment>
                <span id={props.spanId} className={props.classCloseIcon} onClick={props.onClick}></span>
                {(!props.pickupAddrSelected && !props.dropoffAddrSelected) && <span id={props.searchId} className={props.classSearchIcon} onClick={() => searchPlace()}></span>}
            </React.Fragment>
        }
    </GoogleMap>);
}));
