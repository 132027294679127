import React, { Component } from 'react';
import appConfig from '../shared/appConfig/appConfig';
import { handleGetReq } from '../shared/services/AppendHeadersService';
import MUIDataTable from "mui-datatables";
import { Form, Dropdown, Row, Col, ButtonGroup } from 'react-bootstrap';
import toasterService from '../shared/services/ToasterService';
import TripDetails from './TripDetails';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import languageConstants from '../shared/appConfig/languageConstants';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setShowLoader } from '../shared/redux/actions/common.actions'
import { setTripDetails } from '../shared/redux/actions/dashboard.actions'

/* Redux Import End */

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            count: 0,
            page: 0,
            rowsPerPage: 10,
            deliveryStatus: this.props.appSetting.PartnerLocationSettings?.CustomerDelScheduleSmsResponse ? '0,1,2' : '1,2',
            filterStatus: this.props.appSetting.PartnerLocationSettings?.CustomerDelScheduleSmsResponse ? ['Awaiting Customer Confirmation', 'Scheduled', 'In-Progress'] : ['Scheduled', 'In-Progress'],
            sortOrder: 'desc',
            sortBy: this.props.appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.filter((data) => data.defaultSort === true)[0].name,
            searchCriteria: '',
            showModal: false,
            optionValues: this.props.appSetting.PartnerLocationSettings?.CustomerDelScheduleSmsResponse ? ['Awaiting Customer Confirmation', 'Scheduled', 'In-Progress', 'Completed', 'Canceled'] : ['Scheduled', 'In-Progress', 'Completed', 'Canceled'],
            columns: [],
            columnSortDirection: [],
            columnCount: this.props.appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.length,
            nameColumnSortDirection: null,
            dropdownVisibility: false,

        }
        this.tableRef = React.createRef();
    }

    /**
     * setFilterOptions()
     * This function is used to set the filter options.
     */
    setFilterOptions = (name) => {
        return ({
            render: (v) => {
                return `${name}: ${v}`;
            }
        })
    }

    /**
     * updateColumnData()
     * This function is used to update the column data
     */
    updateColumnData = async(sortDirections) => {
        try {
            const { appSetting, setShowLoader } = this.props;
            setShowLoader(true);
            appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.map((item, index) => {
                if(item.options.sort){
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["sortOrder"] = sortDirections[index]
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["customFilterListOptions"] = this.setFilterOptions(item.name)
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["sort"] = true
                } else {
                    appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields[index].options["sort"] = false
                }
                return true
            })
            let tmp = appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields;
            const valueToChange = tmp.filter(item => item.fieldtype === 2)
            const valueToChangeIndex = tmp.findIndex((item) => item.fieldtype === 2);
            //Assign value from the db to ReceiptNumber
            if(valueToChange.length === 1){ 
                // eslint-disable-next-line array-callback-return
                valueToChange.map((key1, index1) => {
                    // eslint-disable-next-line array-callback-return
                    appSetting.PartnerLocationSettings.StaticFields.map((key2, index2) => {
                        if(key1.MappingDBFieldName === key2.MappingDBFieldName) {
                            tmp[valueToChangeIndex].label = key2.DisplayName
                        }
                    });
                })
                
            }
            this.setState({
                columns: tmp
                // columns: appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields
            })
            setShowLoader(false)
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * componentWillMount()
     * This function Executes just before rendering takes place.
     */
    componentWillMount = async () => {
        try {
            const { accessToken, appSetting } = this.props
            let newColumnSortDirection = []
            for(let i=1; i<=this.state.columnCount; i++){
                newColumnSortDirection.push("null")
            }
            await this.setState({
                columnSortDirection: newColumnSortDirection
            })
            this.updateColumnData(newColumnSortDirection);
            
            
            /*Dashboard Metrics API*/
            let dashboardMetrics = await handleGetReq(appConfig.urls.dashboardMetrics,'',accessToken);
            await this.setState({
                supportPhoneNumber: appSetting.BungiiSettings.SupportPhoneNumber
            });
            if (dashboardMetrics !== undefined && dashboardMetrics.data.Error === null) {
                await this.setState({
                    dashboardMetrics: dashboardMetrics.data.DashboardMetrics
                });
            }  
        } catch (e) { console.log(e) }
    }

    /**
     * componentDidMount()
     * This function Executes only after the first render.
     */
    componentDidMount = async () => {
        try {
            await this.getData();
        }
        catch (err) {
            console.log(err)
        }

    }

    /**
     * getData()
     * This function is used to get the data
     */
    getData = async (isSort = false) => {
        try {
            const { accessToken, setShowLoader } = this.props
            const { PartnerLocationSettings } = this.props.appSetting
            setShowLoader(true)
            let data = {
                DeliveryStatus: this.state.deliveryStatus,
                SortBy: this.state.sortBy,
                SortOrder: this.state.sortOrder,
                PageNo: this.state.page,
                PageSize: this.state.rowsPerPage,
                SearchCriteria: this.state.searchCriteria,

                // TODO : rename key to EapiTrackDeliveryEnabled in BE
                IsEapiPortal: PartnerLocationSettings.EapiTrackDeliveryEnabled ? 1 : 0
            }
            
            let response = await handleGetReq(appConfig.urls.deliveryList, data, accessToken);
            
            if (response !== undefined && response.data.Error === null) {
                this.setState({
                    data: response.data.PartnerDeliveries,
                    count: response.data.TotalRowCount
                })
                if(!isSort) {
                    this.tableRef.current.changePage(0); /*reset the page to 0 on click of 'apply', 'reset', 'search', 'sort'*/
                }
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false)
        }
        catch (err) {
            console.log(err);
        }
        
    }

    /**
     * changePage()
     * This function is triggered on changing the page number.
     */
    changePage = async (page, rowsPerPage) => {
        try {
            const { accessToken, setShowLoader } = this.props
            let data = {
                DeliveryStatus: this.state.deliveryStatus,
                SortBy: this.state.sortBy,
                SortOrder: this.state.sortOrder,
                PageNo: page,
                PageSize: this.state.rowsPerPage,
                SearchCriteria: this.state.searchCriteria
            }
            setShowLoader(true)
            let response = await handleGetReq(appConfig.urls.deliveryList, data, accessToken);
            if (response !== undefined && response.data.Error === null) {
                await this.setState({
                    data: response.data.PartnerDeliveries,
                    page: page
                })
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false)
        }
        catch (err) {

        }
    }

    /**
     * changeRowsPerPage()
     * This function is triggered on changing rows per page
     */
    changeRowsPerPage = async (page,rowsPerPage) => {
        this.setState({
            rowsPerPage: rowsPerPage
        })
        try {
            const { accessToken, setShowLoader } = this.props
            let data = {
                DeliveryStatus: this.state.deliveryStatus,
                SortBy: this.state.sortBy,
                SortOrder: this.state.sortOrder,
                PageNo: page,
                PageSize: rowsPerPage,
                SearchCriteria: this.state.searchCriteria
            }
            setShowLoader(true)
            let response = await handleGetReq(appConfig.urls.deliveryList, data, accessToken);
            if (response !== undefined && response.data.Error === null) {
                this.setState({
                    data: response.data.PartnerDeliveries,
                })
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false)
        }
        catch (err) {

        }
    }

    /**
     * handleChange()
     * This function is triggered on click of each filter from the filter list
     */
    handleChange = async (event) => {
        try {
            document.getElementById(event.target.id).setAttribute("checked", "false");
            let checkboxes = document.getElementsByName('deliveryStatus');
            let selectAllCheckbox = document.getElementById('checkUncheckAll');
            let checkboxesChecked = [];
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checkboxesChecked.push(checkboxes[i].id);
                }
            }
            if (checkboxesChecked.length === checkboxes.length) {
                selectAllCheckbox.checked = true
            }
            else {
                selectAllCheckbox.checked = false
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * applyFilters()
     * This function is used when clicked on apply filters
     */
    applyFilters = async (event) => {
        try {
            event.preventDefault();
            let checkboxes = document.getElementsByName('deliveryStatus');
            let checkboxesChecked = [];
            let filterSelected = [];
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    filterSelected.push(checkboxes[i].value);
                    checkboxesChecked.push(checkboxes[i].id);
                }
            }
            await this.setState({
                filterStatus: filterSelected,
                deliveryStatus: checkboxesChecked.length > 0 ? checkboxesChecked.toString() : 0,
                dropdownVisibility: false,
            })
            await this.getData();
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * resetFilters()
     * This function is used when clicked on reset filters
     */
    resetFilters = async () => {
        try {
            await this.setState({
                deliveryStatus: this.props.appSetting.PartnerLocationSettings.CustomerDelScheduleSmsResponse ? '0,1,2' : '1,2',
                filterStatus: this.props.appSetting.PartnerLocationSettings.CustomerDelScheduleSmsResponse ? ['Awaiting Customer Confirmation', 'Scheduled', 'In-Progress'] : ['Scheduled', 'In-Progress'],
                dropdownVisibility: false,
            })
            let checkboxes = document.getElementsByName('deliveryStatus');
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].value === this.state.filterStatus[i]) {
                    checkboxes[i].checked = true;
                }
                else {
                    checkboxes[i].checked = false;
                }
            }
            document.getElementById('checkUncheckAll').checked = false;
            await this.getData();
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * sortChange()
     * This function is used to change the sort direction
     */
    sortChange = (column, order) => {
        this.setState({
            nameColumnSortDirection: order
        });
    };

     /**
     * sort()
     * This function is triggered on click of table head to sort.
     */
    sort = async (column, order) => {
        try {
            const { appSetting, setShowLoader } = this.props;
            var cases = [];
            let isSort = true;
            setShowLoader(true);
            /*Build each case dynamically*/
            appSetting.PartnerLocationSettings.TrackDeliveryListStaticFields.map((item, index) => {
                cases.push(item.name)
                return true
            })
            let sortDirections = [];
            for(let i=1; i<=this.state.columnCount; i++){
                sortDirections.push("null")
            }
            for(let i=1; i<=this.state.columnCount; i++){
                switch (column) {
                    case cases[i]:
                        sortDirections[i] = order;
                        break;
                    default:
                        break;
                }
            }
            
            await this.setState({
                sortOrder: order,
                columnSortDirection: sortDirections,
                sortBy: column
            })
            this.updateColumnData(sortDirections);
            setShowLoader(false)
            await this.getData(isSort);
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * checkUncheckAll()
     * This function is triggered on click of uncheck checkbox.
     */
    checkUncheckAll = () => {
        try {
            let selectAllCheckbox = document.getElementById('checkUncheckAll');
            var checkboxes = document.getElementsByName("deliveryStatus");
            if (selectAllCheckbox.checked === true) {
                for (var i = 0, n = checkboxes.length; i < n; i++) {
                    checkboxes[i].checked = true;
                }
            } else {
                for (var j = 0, m = checkboxes.length; j < m; j++) {
                    checkboxes[j].checked = false;
                }
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * keyPressed()
     * This function is triggered on click of any key pressed.
     */
    keyPressed = async (event) => {
        try {
            document.getElementById('closeIcon').classList.add('closeIcon');
            var code = event.keyCode || event.which;
            if (code === 13) {
                await this.setState({
                    searchCriteria: event.target.value
                })
                await this.getData();
            }
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * clearSearch()
     * This function is triggered on click of close icon of search box.
     */
    clearSearch = async () => {
        try {
            let searchCriteria = document.getElementById('searchText');
            searchCriteria.value = ''
            await this.setState({
                searchCriteria: ''
            })
            document.getElementById('closeIcon').classList.remove('closeIcon');
            await this.getData();
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * handleCloseModal()
     * This function is triggered on click of close modal.
     */
    handleCloseModal = async () => {
        try {
            this.setState({
                showModal: false
            })
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * onRowClick()
     * This function is triggered on click of each row.
     */
    onRowClick = async (rowData) => {
        try {
            await this.setState({
                pickuprequestid: rowData[0],
            })
            await this.getPartnerDetails(rowData[0]);
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * getPartnerDetails()
     * This function is used to get the partner details.
     */
    getPartnerDetails = async (pickuprequestid) => {
        try {
            const { accessToken, setTripDetails, setShowLoader } = this.props
            setShowLoader(true)
            let tripDetails = await handleGetReq(appConfig.urls.tripDetails, { pickuprequestid: pickuprequestid }, accessToken);
            if (tripDetails !== undefined && tripDetails.data.Error === null) {
                /**
                 * Set redux states
                 */
                await setTripDetails(tripDetails.data.Details);
                await this.setState({
                    showModal: true
                })
                setShowLoader(false)
            } else if(tripDetails.data.Error.Code === 20009){
                setShowLoader(false)
                toasterService.showCustomToasty(languageConstants.errors.invalidUser.message, "error");
                setTimeout(function(){ 
                    window.location.replace(`/`);
                }, 2000);
            }
            else {
                toasterService.showCustomToasty(tripDetails.data.Error.Message, "error");
            }
        } catch (e) { console.log(e) }
    }

    /**
     * setDropdownVisibility()
     * This function is used to hide or show the dropdown.
     */
    setDropdownVisibility = async() => {
        try{
            await this.setState({
                dropdownVisibility: !this.state.dropdownVisibility
            })
        } catch(err){
            console.log(err);
        }
    }

    /**
     * handleClickAway()
     * This function is used to listen if clicked outside.
     */
    handleClickAway = async() => {
        try{
            await this.setState({
                dropdownVisibility: false
            })
        } catch(err){
            console.log(err);
        }
    }

    render() {
        const data = this.state.data;
        let filterStatusString = this.state.filterStatus;
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (

            <div
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    this.setDropdownVisibility();
                }}
                className="caret filter"
            >
                {children}
            </div>
        ));
        const AddButton = () => (
            <div className="row">
                <div className="search">
                    <input label="Search" maxLength="99" name="searchText" autoComplete="off" id="searchText" className="searchText" placeholder="Search" onKeyPress={this.keyPressed.bind(this)} />
                    <link onClick={this.clearSearch} id="closeIcon"></link>
                </div>
                <div className="col">
                    <Dropdown className="filterDropdown" show={this.state.dropdownVisibility}>
                        <ClickAwayListener onClickAway={this.handleClickAway}> 
                            <div>
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                    <span>Filter:</span> {this.state.filterStatus.length === this.state.optionValues.length ? 'All' : filterStatusString.join(", ")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="inner-content">
                                        <Form.Group controlId="checkbox">
                                            <Form.Check type="checkbox" name="checkbox" label="Check / uncheck all" id="checkUncheckAll" defaultChecked={false}
                                                onChange={event => this.checkUncheckAll(event)}
                                            />
                                        </Form.Group>
                                        {
                                            this.state.optionValues.map((item, index) => (
                                                <Form.Group controlId={this.props.appSetting.PartnerLocationSettings.CustomerDelScheduleSmsResponse ? index : (index + 1)} key={item}>
                                                    <Form.Check type="checkbox" name="deliveryStatus" label={item} value={item} defaultChecked={item === this.state.filterStatus[index] ? true : false}
                                                        onChange={event => this.handleChange(event)}
                                                    />
                                                </Form.Group>
                                            ))
                                        }
                                        <ButtonGroup>
                                            <button className="btn" onClick={this.applyFilters}>Apply</button>
                                            <button className="btn btn-reset" onClick={this.resetFilters}>RESET</button>
                                        </ButtonGroup>
                                    </div>
                                </Dropdown.Menu>
                            </div>
                        </ClickAwayListener>
                    </Dropdown>
                </div>

            </div>
        );
        const columns = this.state.columns;
        const options = {
            filter: false,
            filterType: 'checkbox',
            responsive: 'standard',
            print: false,
            selectableRows: "none",
            download: false,
            serverSide: true,
            viewColumns: false,
            count: this.state.count,
            page: this.state.page,
            rowsPerPageOptions: [10, 20, 30],
            customToolbar: AddButton,
            search: false,
            searchText: this.state.searchCriteria,
            textLabels: {
                body: {
                    noMatch: 'Sorry, no records found',
                },
            },
            onColumnSortChange: async (changedColumn, direction) => {
                let order = "desc";
                if (direction === "asc") {
                    order = "asc";
                }
        
                this.sort(changedColumn, order);
                this.sortChange(changedColumn, order)
            },
            onRowClick: this.onRowClick,
            onTableChange: (action, tableState) => {
                switch (action) {
                    case 'changePage':
                        this.changePage(tableState.page, tableState.rowsPerPage);
                        break;
                    case 'changeRowsPerPage':
                        this.changeRowsPerPage(tableState.page, tableState.rowsPerPage);
                        break;
                    case 'resetFilters':
                        this.resetFilters();
                        break;
                    default:
                        return 'No action.';
                }
            }
        };
        return (
            <React.Fragment>
                <Row className="metrics">
                    <Col className="total-deliveries p-r-9">
                        <div className="card">
                            <label>Total Deliveries</label>
                            <p>{this.state.dashboardMetrics && this.state.dashboardMetrics.TotalDeliveriesCompleted}</p>
                        </div>
                    </Col>
                    <Col className="avg-rating p-l-7 p-r-9">
                        <div className="card">
                            <label>Avg. Driver Rating</label>
                            <p>{this.state.dashboardMetrics && this.state.dashboardMetrics.AverageDriverRating}<span>stars</span></p>
                        </div>
                    </Col>
                    <Col className="avg-cost p-l-7 p-r-9">
                        <div className="card">
                            <label>Average Cost</label>
                            <p><sup>$</sup>{this.state.dashboardMetrics && this.state.dashboardMetrics.AverageCost}</p>
                        </div>
                    </Col>
                    <Col className="avg-distance p-l-7 p-r-9">
                        <div className="card">
                            <label>Average Distance</label>
                            <p>{this.state.dashboardMetrics && this.state.dashboardMetrics.AverageDistance}<span>miles</span></p>
                        </div>
                    </Col>
                    <Col className="current-deliveries p-l-7">
                        <div className="card">
                            <label className="text-grey">Current Deliveries</label>
                            <p>{this.state.dashboardMetrics && this.state.dashboardMetrics.CurrentDeliveries}</p>
                        </div>
                    </Col>
                </Row>

                <Row className={`dataTable ${this.state.sortBy} ${this.state.sortOrder}`}>
                    <Col>
                        <MUIDataTable
                            title={"Delivery List"}
                            innerRef={this.tableRef}
                            data={data}
                            className={columns.filter((item) => item.options.display === true).length === 5 ? "five-cols" : ""}
                            columns={columns}
                            options={options}
                        />
                    </Col>
                </Row>
                {this.state.showModal &&
                    < TripDetails showModal={this.state.showModal} handleCloseModal={this.handleCloseModal} tripDetails={this.state.tripDetails} pickuprequestid={this.state.pickuprequestid} getData={this.getData} supportPhoneNumber={this.state.supportPhoneNumber}></TripDetails>
                }

            </React.Fragment>
        );
    }
}

export default connect(
    (
        { 
            user: {
                accessToken
            }, 
            common: {
                showLoader, appSetting
            },
            dashboard: {
                tripDetails
            }
        }
    ) => 
    (
        {
            accessToken,
            tripDetails,
            showLoader,
            appSetting
        }
    ),
    dispatch => (
        {
            setTripDetails: tripDetails => dispatch(setTripDetails(tripDetails)),
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
        }
    )
)(Dashboard);
