export const userActionTypes = {
    SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
    SET_PARTNER_LOCATION_REFERENCE: 'SET_PARTNER_LOCATION_REFERENCE',
    SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
    SET_IS_EAPI_USER: 'SET_IS_EAPI_USER',
    SET_IS_TRACK_DELIVERIES_ENABLED: 'SET_IS_TRACK_DELIVERIES_ENABLED',
    SET_QUICK_ACCESS: 'SET_QUICK_ACCESS',
    SET_QUOTE_ONLY: 'SET_QUOTE_ONLY',
}

export const setIsAuthenticated = isAuthenticated => ({
    type: userActionTypes.SET_IS_AUTHENTICATED,
    payload: isAuthenticated
});

export const setPartnerLocationReference = partnerLocationReference => ({
    type: userActionTypes.SET_PARTNER_LOCATION_REFERENCE,
    payload: partnerLocationReference
});

export const setAccessToken = accessToken => ({
    type: userActionTypes.SET_ACCESS_TOKEN,
    payload: accessToken
});

export const setIsTrackDeliveriesEnabled = flag => ({
    type: userActionTypes.SET_IS_TRACK_DELIVERIES_ENABLED,
    payload: flag
});

export const setIsEapiUser = flag => ({
    type: userActionTypes.SET_IS_EAPI_USER,
    payload: flag
});

export const setQuickAccessUser = quickAccessUser => ({
    type: userActionTypes.SET_QUICK_ACCESS,
    payload: quickAccessUser
});

export const setQuoteOnlyUser = quoteOnlyUser => ({
    type: userActionTypes.SET_QUOTE_ONLY,
    payload: quoteOnlyUser
});

