import React, { Component } from "react";
import loaderService from "../../services/LoaderService";
import { Container, Row, Col, Dropdown, ButtonGroup } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import logout from "../../../assets/images/logout-icon.png";
import {
	handlePostReq,
	handleGetReq,
} from "../../../shared/services/AppendHeadersService";
import appConfig from "../../appConfig/appConfig";
import Alert from "../../../shared/layouts/common/Alert";
import { withStyles } from "@material-ui/core/styles";
import hamburger from "../../../assets/images/icons/head-hamburger.svg";
import close from "../../../assets/images/icons/close_btn.svg";
import Switch from "../../../pages/Switch";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import languageConstants from "../../../shared/appConfig/languageConstants";
import clearAppsettingData from "../../../shared/helpers/clearAppsettingData";
import Password from "../../../shared/layouts/common/Password";
import AuthService from "../../../shared/services/AuthService";
import { withRouter } from "react-router-dom";
import defaultGeofence from "../../helpers/defaultGeofence";
import getFullAddressFromObject from "../../../shared/helpers/getFullAddressFromObject";
import { isPointInPolygon } from "geolib";
import { CalenderModal } from "./CalenderModal";
import { dateUtils } from "../../utilities/dateUtils";
import { downloadCSV } from "../../utilities/downloadCsv";
import yellowClose from "../../../assets/images/icons/yellow_close_btn.svg";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setActiveTab,
	setShowLoader,
	setCommonInitialState,
	setFormIsFilled,
	setAppSetting,
	setKioskMode,
	setDisplayKioskMode,
	setLockedAccess,
	setShowPrice,
} from "../../../shared/redux/actions/common.actions";
import {
	setDefaultGeofenceIndex,
	setDefaultGeofence,
	setTimeZone,
	setShowCheckbox,
	setDriverHelpCarryFieldMessage,
	resetDataOnPageChange,
	setPickupAddressess,
} from "../../../shared/redux/actions/getEstimate.actions";
import { setDeliveryDetailsInitialState } from "../../../shared/redux/actions/deliveryDetails.actions";
import {
	setIsAuthenticated,
	setAccessToken,
	setQuickAccessUser,
} from "../../../shared/redux/actions/user.actions";
import { Link } from "react-router-dom";
/* Redux Import End */

const styles = {
	popupRoot: {
		position: "relative",
	},
};

const defaultReportDates = {
	startDate: false,
	endDate: false,
	key: "selection",
};

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAlertModal: false,
			showTimeoutAlert: false,
			path: "",
			anchorEl: null,
			open: false,
			placement: "",
			time: "05:00",
			openPopper: false,
			clickedOnSetting: false,
			clickedKioskMode: false,
			continue: false,
			urlValue: "",
			errors: {
				password: "",
			},
			calenderStatus: false,
			reportDates: {
				startDate: false,
				endDate: false,
				key: "selection",
			},
			reportsError: false,
			showNotification: false,
		};
		this.events = [
			"load",
			"mousemove",
			"mousedown",
			"click",
			"scroll",
			"keypress",
		];
		this.warn = this.warn.bind(this);
		this.resetTimeout = this.resetTimeout.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.subscribe = this.subscribe.bind(this);
		this.handleClickAway = this.handleClickAway.bind(this);

		for (var i in this.events) {
			window.addEventListener(this.events[i], this.resetTimeout);
		}

		this.setTimeout();
	}

	/**
	 * componentDidMount()
	 * This function Executes only after the first render.
	 */
	componentDidMount = async () => {
		try {
			const { appSetting } = this.props;
			appSetting.PartnerLocationSettings.CanDisplayBannerMessage &&
				this.setState({
					showNotification:
						appSetting.PartnerLocationSettings.CanDisplayBannerMessage,
				});
		} catch (err) {
			console.log(err);
		}
	};

	clearTimeout = () => {
		if (this.warnTimeout) clearTimeout(this.warnTimeout);
	};

	setTimeout = async () => {
		this.warnTimeout = await setTimeout(
			this.warn,
			process.env.REACT_APP_SESSION_TIMEOUT * (3600000 - 300000)
		);
	};

	resetTimeout = () => {
		this.clearTimeout();
		this.setTimeout();
	};

	warn = async () => {
		await this.setState({
			showTimeoutAlert: true,
		});
		this.timer();
	};

	timer() {
		var counter = 0;
		if (counter === 0) {
			var fiveMinutes = 300,
				display = document.getElementById("timer");
			this.startTimer(fiveMinutes, display);
			counter++;
		} else {
			counter++;
		}
	}

	startTimer(duration, display) {
		const { setShowLoader } = this.props;
		let that = this;
		var timer = duration,
			minutes,
			seconds;
		this.setInterval = setInterval(function () {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;
			display.innerHTML = minutes + ":" + seconds;
			if (--timer <= 0) {
				setShowLoader(true);
				that.logoutHandler();
				that.handleClose();
				setShowLoader(false);
			}
		}, 1000);
	}

	toggleLoader = (status) => {
		loaderService.loader(status);
	};

	goToDashboard = () => {
		const { setActiveTab, formFilled } = this.props;
		if (formFilled) {
			this.setState({
				showAlertModal: true,
				openPopper: false,
			});
		} else {
			localStorage.clear();
			setActiveTab("getEstimate");
			window.location.replace("/dashboard");
		}
	};

	handleClose = () => {
		this.setState({
			showTimeoutAlert: false,
		});
		this.resetTimeout();
		if (this.setInterval) {
			clearInterval(this.setInterval);
		}
	};

	/**
	 * logoutHandler()
	 * This function is called when user clicks on the Log Out button.
	 */
	logoutHandler = async () => {
		const { setShowLoader, accessToken, setIsAuthenticated, setAccessToken } =
			this.props;
		setShowLoader(true);
		try {
			let logout = await handlePostReq(appConfig.urls.logout, "", accessToken);
			if (logout.status === 200) {
				/**
				 * Clear appSetting data
				 */
				clearAppsettingData();

				localStorage.removeItem("persist:root");
				localStorage.clear();
				sessionStorage.clear();
				setIsAuthenticated(false);
				setAccessToken("");

				window.location.replace("/");
			}
			setShowLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * getGeofenceByAddress()
	 * This function is used to select the geofence based on address
	 */
	getGeofenceByAddress = () => {
		try {
			const { geofence } = this.props;
			let newLat = "",
				newLng = "";

			let defaultAddr = this.getDefaultPickupAddress();

			if (defaultAddr) {
				newLat = defaultAddr[0].Location.Latitude;
				newLng = defaultAddr[0].Location.Longitude;
				for (let i = 0; i < geofence.length; i++) {
					var isValid = isPointInPolygon(
						{ latitude: newLat, longitude: newLng },
						geofence[i]
					);
					if (isValid) {
						return i;
					}
				}
				if (!isValid) {
					return defaultGeofence();
				}
			} else {
				return defaultGeofence();
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getDefaultPickupAddress()
	 * This function is used to find the pickup address from the address array.
	 */
	getDefaultPickupAddress = () => {
		try {
			const { appSetting, setPickupAddressess, pickupAddressess } = this.props;
			let defaultAddress = null;
			/*Fetch and store the list of default addressess*/
			if (
				appSetting.PartnerLocationSettings.DefaultPickupLocationInfo != null
			) {
				let pickupAddressArray =
					appSetting.PartnerLocationSettings.DefaultPickupLocationInfo.Address;
				let newPickupAddressArray = [];

				if (pickupAddressArray.length) {
					let address = "";
					pickupAddressArray.forEach(function (value, i) {
						address = getFullAddressFromObject(value);
						newPickupAddressArray.push(address);
					});
				}

				/**
				 * Set redux state
				 */
				setPickupAddressess(
					pickupAddressess.length ? pickupAddressess : newPickupAddressArray
				);

				defaultAddress = pickupAddressArray.filter((data) => data.IsDefault);

				return defaultAddress;
			} else {
				return defaultAddress;
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * goToNewDelivery()
	 * This function is called when user clicks on New Deliveries.
	 */
	goToNewDelivery = () => {
		try {
			const {
				setActiveTab,
				setDeliveryDetailsInitialState,
				setCommonInitialState,
				resetDataOnPageChange,
				appSetting,
				showPrice,
				setShowPrice
			} = this.props;
			let defaultGeofenceVal = this.getGeofenceByAddress();

			let data = {
				defaultGeofenceIndex: defaultGeofenceVal,
				defaultGeofence: appSetting.GeofenceSettings[defaultGeofenceVal],
				timeZone:
					appSetting.GeofenceSettings[defaultGeofenceVal]["TimezoneIdentifier"],
			};
			/**
			 * Set redux state
			 */
			setDeliveryDetailsInitialState();
			resetDataOnPageChange(data);
			setCommonInitialState();
			/**
			 * Clear appSetting data
			 */
			clearAppsettingData();

			if (this.state.continue && this.state.clickedOnSetting) {
				setShowPrice(showPrice)
				this.props.history.push("/dashboard");
			} else {
				setActiveTab("getEstimate");
				this.props.history.push("/request-bungii");
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleCloseAlert()
	 * Alert poup close icon
	 */
	handleCloseAlert = async () => {
		this.setState({
			showAlertModal: false,
			openPopper: true,
			clickedOnSetting: false,
		});
	};

	/**
	 * handleContinue()
	 * Alert poup continue button
	 */
	handleContinue = async () => {
		await this.setState({
			showAlertModal: false,
			continue: true,
		});
		this.goToNewDelivery();
	};

	/**
	 * handleImgClick()
	 * This function is called when user clicks on partner logo.
	 */
	handleImgClick = (e) => {
		e.preventDefault();
		if(this.props.isTrackDeliveriesEnabled) return;
		if (this.props.location.pathname === "/request-bungii") return;
		if (this.props.location.pathname === "/success") {
			this.goToNewDelivery();
		}
		const { formFilled } = this.props;
		if (formFilled) {
			this.setState({
				showAlertModal: true,
			});
		} else {
			this.goToNewDelivery();
		}
	};

	/**
	 * handleClickAway()
	 * This function is called when user clicks anywhere outside to close the cog-wheel menu.
	 */
	handleClickAway = () => {
		this.setState({
			openPopper: false,
		});
	};

	/**
	 * handlePopperClick()
	 * This fucntion is called when user clicks on the cog-wheel icon
	 */
	handlePopperClick = (event) => {
		const { lockedAccess } = this.props;
		this.setState({
			clickedOnSetting: !this.state.openPopper,
			anchorEl: event.currentTarget,
		});
		if (lockedAccess) {
			this.setState({
				open: true,
			});
		} else {
			this.setState({
				anchorEl: event.currentTarget,
				openPopper: !this.state.openPopper,
			});
		}
	};

	/**
	 * changeKioskMode()
	 * Function will be called on click of Admin access switch
	 */
	changeKioskMode = async (event) => {
		const { setKioskMode, setLockedAccess } = this.props;
		try {
			if (!event.target.checked) {
				setKioskMode(event.target.checked);
				setLockedAccess(true);

				this.setState({
					openPopper: false,
				});
			} else {
				setKioskMode(event.target.checked);
				setLockedAccess(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * cancelSubscription()
	 * This function will be called on click of the cancel button
	 */
	cancelSubscription = (event) => {
		event.preventDefault();
		this.setState({
			errors: {
				...this.state.errors,
				password: "",
			},
		});
		if (this.state.clickedOnSetting) {
			this.setState({
				open: false,
				clickedOnSetting: false,
			});
		}
		if (this.state.clickedKioskMode) {
			this.setState({
				open: false,
				clickedKioskMode: false,
			});
		}
	};

	/**
	 * subscribe()
	 * This function is called when the user clicks on Gain access
	 */
	subscribe = async (event) => {
		event.preventDefault();

		try {
			const {
				partnerLocationReference,
				setShowLoader,
				setKioskMode,
				setLockedAccess,
			} = this.props;
			let data = {
				PartnerLocationReference: partnerLocationReference,
				Password: this.state.password,
			};

			/* check if password is empty */
			if (
				this.state.password === undefined ||
				this.state.password === "" ||
				this.state.password === null
			) {
				this.setState({
					errors: {
						...this.state.errors,
						password: "Password is required.",
					},
				});
			} else {
				setShowLoader(true);

				let response = await AuthService.isUserAuthorized(data);

				if (response.data.AccessToken !== null) {
					/* If user clicks on the cog-wheel icon. */
					if (this.state.clickedOnSetting) {
						setKioskMode(false);
						setLockedAccess(false);
						this.setState({
							open: false,
							clickedOnSetting: true,
							openPopper: true,
						});
					}
				} else {
					this.setState({
						errors: {
							...this.state.errors,
							password: response.data.Error.Message + ".",
						},
					});
				}
				setShowLoader(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * onPasswordChange()
	 * This function is called when user enters the password.
	 */
	onPasswordChange = (event) => {
		this.setState({
			password: event.target.value,
			errors: {
				...this.state.errors,
				password: "",
			},
		});
	};

	/**
	 * continueAsAdmin()
	 * This function is called when user clicks on Continue as admin
	 */
	continueAsAdmin = () => {
		window.location.replace("/request-bungii");
	};

	/**
	 * componentWillMount()
	 * This function Executes just before rendering takes place.
	 */
	componentWillMount = async () => {
		//To be used on Deployed Serves
		const fullUrl = window.location.pathname;
		this.setState({
			urlValue: fullUrl.split("/")[1],
		});
	};

	handleCalenderClose = () => {
		this.setState({
			calenderStatus: false,
			reportsError: false,
			reportDates: defaultReportDates,
		});
	};

	/**
	 * generateReport()
	 * This function is called when user clicks on Generate Reports Button
	 */
	generateReport = async () => {
		try {
			const { setShowLoader, partnerLocationReference, accessToken } =
				this.props;
			this.setState({ reportsError: false });
			setShowLoader(true);
			const { reportDates } = this.state;
			const StartDate = dateUtils.getDate(reportDates.startDate);
			const EndDate = dateUtils.getDate(reportDates.endDate);
			const params = {
				StartDate,
				EndDate,
			};
			let report = await handleGetReq(
				appConfig.urls.generateReports,
				params,
				accessToken
			);
			if (
				!report ||
				report.status !== 200 ||
				!report.data ||
				!report.data.Deliveries ||
				report.data.Deliveries.length === 0
			) {
				setShowLoader(false);
				this.setState({ reportsError: true });
				this.setState(
					{ reportDates: defaultReportDates, calenderStatus: false },
					() => {
						this.setState({ calenderStatus: true });
					}
				);
				return;
			}
			await downloadCSV(`Partner-deliveries.csv`, report.data.Deliveries);
			setShowLoader(false);
			this.setState(
				{ reportDates: defaultReportDates, calenderStatus: false },
				() => {
					this.setState({ calenderStatus: true });
				}
			);
		} catch (e) {
			setShowLoader(false);
		}
	};

	handleDateChange = (dates) => {
		const { reportsError } = this.state;
		if (reportsError) {
			this.setState({ reportsError: false });
		}
		this.setState({ reportDates: dates });
	};

	render() {
		const {
			classes,
			kioskMode,
			displayKioskMode,
			lockedAccess,
			quickAccessUser,
			quoteOnlyUser,
			appSetting,
		} = this.props;
		const { calenderStatus, reportDates, reportsError, showNotification } =
			this.state;
		const { generateReport, handleCalenderClose, handleDateChange } = this;
		return (
			<React.Fragment>
				{/*iPad Portrait view*/}
				<header className="ipad-portrait-view">
					<Container>
						<Row>
							{this.props.partnerLogo && (
								<Link
									onClick={this.handleImgClick}
									to="/request-bungii"
									onContextMenu={(e) => e.preventDefault()}
								>
									<Col
										className="pr-0 partner-logo d-flex align-items-center"
										md="auto"
									>
										<img src={this.props.partnerLogo} alt="Partner Logo" />
									</Col>
								</Link>
							)}
							<Col className="pl-0 powered-by align-self-center d-block">
								<img src={logo} width="93" className="logo" alt="Bungii Logo" />
							</Col>

							<Col xs="10" className="mt-2 pr-0 support-col">
								<div className="d-inline-block support">
									<p x-ms-format-detection="none" className="phone">
										<strong>Text Support:</strong>
										<span className="m-l-3">
											{this.props.supportPhoneNumber}
										</span>
									</p>
									{this.props.callSupportPhoneNumber && (
										<p x-ms-format-detection="none" className="phone">
											<strong>Call Support:</strong>
											<span className="m-l-3">
												{this.props.callSupportPhoneNumber}
											</span>
										</p>
									)}
									{this.props.supportEmailId && (
										<p className="d-flex">
											<strong className="text-nowrap">Email Support:</strong>
											<span className="m-l-3">
												<a
													href={`mailto:${this.props.supportEmailId}`}
													className="text-break"
												>
													{this.props.supportEmailId}
												</a>
											</span>
										</p>
									)}
								</div>
							</Col>
							{!quickAccessUser && !quoteOnlyUser && <Col xs="2" className="mt-2 text-right logout-col">
								<img
									src={logout}
									alt="Logout"
									onClick={this.logoutHandler}
									className="cursor-pointer"
								/>
							</Col>}
						</Row>
					</Container>
				</header>
				{/*iPad Landscape view*/}
				<header
					className={
						showNotification
							? "ipad-landscape-view height-195"
							: "ipad-landscape-view"
					}
				>
					{showNotification &&
						appSetting.PartnerLocationSettings.CommunicationBannerMessage && (
							<div className="notificationBox">
								{appSetting.PartnerLocationSettings.CommunicationBannerMessage}
								<img
									src={yellowClose}
									alt="Close"
									onClick={() => this.setState({ showNotification: false })}
								/>
							</div>
						)}
					<Container>
						<Row>
							{this.props.partnerLogo && (
								<Link
									onClick={this.handleImgClick}
									to="/request-bungii"
									onContextMenu={(e) => e.preventDefault()}
								>
									<Col
										className="pr-0 partner-logo d-flex align-items-center"
										md="auto"
									>
										<img src={this.props.partnerLogo} alt="Partner Logo" />
									</Col>
								</Link>
							)}
							<Col className="pl-0 powered-by align-self-start m-t-15">
								<img src={logo} width="93" className="logo" alt="Bungii Logo" />
							</Col>

							{this.state.urlValue !== "Pickup" && (
								<Col className="text-right m-r-5 align-self-start">
									<Row>
										<Col className="m-t-10">
											<div className="d-inline-block support">
												<p x-ms-format-detection="none" className="phone">
													<strong>Text Support:</strong>
													<span className="m-l-3">
														{this.props.supportPhoneNumber}
													</span>
												</p>
												{this.props.callSupportPhoneNumber && (
													<p x-ms-format-detection="none" className="phone">
														<strong>Call Support:</strong>
														<span className="m-l-3">
															{this.props.callSupportPhoneNumber}
														</span>
													</p>
												)}
												{this.props.supportEmailId && (
													<p>
														<strong>Email Support:</strong>
														<span className="m-l-3">
															<a href={`mailto:${this.props.supportEmailId}`}>
																{this.props.supportEmailId}
															</a>
														</span>
													</p>
												)}
											</div>
											{!quickAccessUser && !quoteOnlyUser && (
												<div
													className={`d-inline-block ml-2 ${
														this.props.callSupportPhoneNumber && "m-t-9"
													}`}
												>
													<Dropdown
														alignRight
														className={
															lockedAccess
																? "dropdown-menu-right lock-icon"
																: "dropdown-menu-right"
														}
													>
														<ClickAwayListener
															onClickAway={this.handleClickAway}
														>
															<div className={classes.popupRoot}>
																<i
																	className="header-btn logout-btn"
																	onClick={this.handlePopperClick}
																	title={
																		this.state.openPopper ? "Close" : "Menu"
																	}
																>
																	{this.state.openPopper ? (
																		<img src={close} alt="Close" />
																	) : (
																		<img src={hamburger} alt="Menu" />
																	)}
																</i>
																{this.state.openPopper ? (
																	<div
																		className={
																			displayKioskMode
																				? "quickAccessOptions"
																				: "quickAccessOptions no-access"
																		}
																	>
																		{displayKioskMode ? (
																			<div>
																				<Switch
																					className="m-r-20"
																					checked={kioskMode}
																					onChange={this.changeKioskMode}
																					name="kioskMode"
																					color="primary"
																				/>
																				<div className="admin-access-desc">
																					<p className="m-b-5">
																						<span>OFF</span> (Recommended) -
																						We’ll ask the user for a password to
																						view certain information, like
																						details for other deliveries.
																					</p>
																					<p>
																						<span>ON</span> - We will <u>not</u>{" "}
																						require a password to access any
																						information within the portal.
																					</p>
																				</div>
																				<hr className="m-b-10" />
																			</div>
																		) : (
																			""
																		)}
																		{this.props.reqPath === "/request-bungii" ||
																		this.props.reqPath ===
																			"/delivery-details" ||
																		this.props.reqPath === "/success"
																		? (
																			<span
																				className="header-menu-btn"
																				id="track-deliveries"
																				onClick={this.goToDashboard}
																			>
																				Track Deliveries
																			</span>
																		) : this.props.reqPath === "/dashboard" &&
																				!this.props.isTrackDeliveriesEnabled ? (
																			<span
																				className="header-menu-btn"
																				id="new-delivery"
																				onClick={this.goToNewDelivery}
																			>
																				New Delivery
																			</span>
																		) : ''}
																		{!this.props.isTrackDeliveriesEnabled && <hr className="m-t-10" />}
																		<span
																			className="header-menu-btn"
																			onClick={() =>
																				this.setState({
																					calenderStatus: !calenderStatus,
																				})
																			}
																		>
																			Reports
																		</span>
																		<hr className="m-t-10" />
																		<span
																			className="header-menu-btn logout-btn"
																			onClick={this.logoutHandler}
																		>
																			Log Out
																		</span>
																	</div>
																) : (
																	""
																)}
															</div>
														</ClickAwayListener>
													</Dropdown>
												</div>
											)}
										</Col>
									</Row>
								</Col>
							)}
						</Row>
					</Container>
				</header>
				<Alert
					showAlertModal={this.state.showAlertModal}
					handleCloseAlert={this.handleCloseAlert}
					showCloseIcon={true}
					alertType="alert-lg"
				>
					<h2>{languageConstants.redirectAlertBox.title}</h2>
					<p>{languageConstants.redirectAlertBox.description}</p>
					<ButtonGroup>
						<button className="btn" onClick={this.handleCloseAlert}>
							Cancel
						</button>
						<button className="btn btn-clear" onClick={this.handleContinue}>
							Continue
						</button>
					</ButtonGroup>
				</Alert>

				<Alert
					showAlertModal={this.state.showTimeoutAlert}
					handleCloseAlert={this.handleClose}
					showCloseIcon={false}
					alertType="alert-sm"
				>
					<h2>Session Expiring!</h2>
					<p>
						Your session will expire in{" "}
						<span id="timer">{this.state.time}</span> minutes. Please click on
						continue to keep working.
					</p>
					<ButtonGroup>
						<button className="btn" onClick={this.handleClose}>
							Continue
						</button>
					</ButtonGroup>
				</Alert>

				<Password
					open={this.state.open}
					cancelSubscription={this.cancelSubscription}
					subscribe={this.subscribe}
					onPasswordChange={this.onPasswordChange}
					value={this.state.value}
					errors={this.state.errors.password}
					clickedPay={false}
					clickedKioskMode={this.state.clickedKioskMode}
					continueAsAdmin={this.continueAsAdmin}
					reqPath={this.state.reqPath}
					clickedOnSetting={this.state.clickedOnSetting}
				/>
				{calenderStatus && (
					<CalenderModal
						calenderStatus={calenderStatus}
						reportDates={reportDates}
						handleDateChange={handleDateChange}
						close={handleCalenderClose}
						generateReport={generateReport}
						reportsError={reportsError}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default connect(
	({
		getEstimate: {
			editPickup,
			pickupPoint,
			minDate,
			geofence,
			defaultGeofenceIndex,
			rerenderDropoff,
			pickupDate,
			isDuo,
			defaultPoint,
			pickupAdd,
			pickupAddObj,
			defaultAdd,
			loadUnloadTimeShow,
			defaultGeofence,
			pickupTime,
			tripType,
			dropoffAdd,
			dropoffPoint,
			timeZone,
			dropoffAddObj,
			nextCalculatedDate,
			pickupTimeValues,
			loadUnload,
			estimate,
			loadTime,
			pickupRequestID,
			pickupAddressess,
		},
		user: {
			accessToken,
			isTrackDeliveriesEnabled,
			partnerLocationReference,
			isAuthenticated,
			quickAccessUser,
			quoteOnlyUser,
		},
		deliveryDetails: {
			pickupDetails,
			loadingUnloadingTime,
			noOfDrivers,
			scheduledTimestamp,
			paymentMethodSelected,
			lastFour,
		},
		common: {
			appSetting,
			activeTab,
			showLoader,
			kioskMode,
			displayKioskMode,
			lockedAccess,
			formFilled,
			showPrice,
		},
	}) => ({
		accessToken,
		isTrackDeliveriesEnabled,
		activeTab,
		pickupRequestID,
		appSetting,
		pickupDetails,
		loadingUnloadingTime,
		noOfDrivers,
		scheduledTimestamp,
		editPickup,
		pickupPoint,
		minDate,
		geofence,
		defaultGeofenceIndex,
		rerenderDropoff,
		pickupDate,
		isDuo,
		defaultPoint,
		pickupAdd,
		pickupAddObj,
		defaultAdd,
		loadUnloadTimeShow,
		defaultGeofence,
		pickupTime,
		tripType,
		dropoffAdd,
		dropoffPoint,
		timeZone,
		dropoffAddObj,
		nextCalculatedDate,
		pickupTimeValues,
		loadUnload,
		estimate,
		loadTime,
		paymentMethodSelected,
		lastFour,
		showLoader,
		partnerLocationReference,
		kioskMode,
		displayKioskMode,
		lockedAccess,
		formFilled,
		isAuthenticated,
		quickAccessUser,
		quoteOnlyUser,
		pickupAddressess,
		showPrice,
	}),
	(dispatch) => ({
		setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
		setShowPrice: (showPrice) => dispatch(setShowPrice(showPrice)),
		setDefaultGeofenceIndex: (defaultGeofenceIndex) =>
			dispatch(setDefaultGeofenceIndex(defaultGeofenceIndex)),
		setDefaultGeofence: (defaultGeofence) =>
			dispatch(setDefaultGeofence(defaultGeofence)),
		setTimeZone: (timeZone) => dispatch(setTimeZone(timeZone)),
		setShowCheckbox: (showCheckbox) => dispatch(setShowCheckbox(showCheckbox)),
		setDriverHelpCarryFieldMessage: (driverHelpCarryFieldMessage) =>
			dispatch(setDriverHelpCarryFieldMessage(driverHelpCarryFieldMessage)),
		setDeliveryDetailsInitialState: (newValues1) =>
			dispatch(setDeliveryDetailsInitialState(newValues1)),
		setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
		setCommonInitialState: (commonInitialState) =>
			dispatch(setCommonInitialState(commonInitialState)),
		setFormIsFilled: (formFilled) => dispatch(setFormIsFilled(formFilled)),
		resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
		setAppSetting: (appSetting) => dispatch(setAppSetting(appSetting)),
		setKioskMode: (kioskMode) => dispatch(setKioskMode(kioskMode)),
		setDisplayKioskMode: (displayKioskMode) =>
			dispatch(setDisplayKioskMode(displayKioskMode)),
		setLockedAccess: (lockedAccess) => dispatch(setLockedAccess(lockedAccess)),
		setIsAuthenticated: (isAuthenticated) =>
			dispatch(setIsAuthenticated(isAuthenticated)),
		setAccessToken: (accessToken) => dispatch(setAccessToken(accessToken)),
		setQuickAccessUser: (quickAccessUser) =>
			dispatch(setQuickAccessUser(quickAccessUser)),
		setPickupAddressess: (pickupAddressess) =>
			dispatch(setPickupAddressess(pickupAddressess)),
	})
)(withStyles(styles)(withRouter(Header)));
