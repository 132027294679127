/* global */
import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker, DirectionsRenderer } from "react-google-maps";
import appConfig from '../../shared/appConfig/appConfig';
import pickupMarker from '../../assets/images/pickup.png';
import dropoffMarker from '../../assets/images/dropoff.png';
import returnMarker from '../../assets/images/icon-return.svg';
import driverLocation from '../../assets/images/driverLocation.png';
import loaderService from '../../shared/services/LoaderService';
import car from '../../assets/images/car-marker.svg';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setDirections } from '../../shared/redux/actions/getEstimate.actions';

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            geofence: [],
            markerDropPosition: null,
            pickupPoint: this.props.pickupPoint,
            dropoffPoint: this.props.dropoffPoint
        }
    }
	//Loader
	showLoader = (status) => {
		loaderService.loader(status);
	}
    /* Component should only update ( meaning re-render ), when the user selects the address, or drags the pin */
    shouldComponentUpdate(nextProps, nextState) {
        // this.showLoader(true);
        const { directions } = this.props;
        if (nextProps.pickupPoint !== this.props.pickupPoint ||
            nextProps.dropoffPoint !== this.props.dropoffPoint) {
            return true
        }
        
        if (nextState.directions !== null) {
            if (nextProps.pickupPoint !== this.props.pickupPoint ||
                nextProps.dropoffPoint !== this.props.dropoffPoint || nextProps.directions !== directions) {
                return true
                
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }

    componentDidUpdate = (prevProps) => {
        const {setDirections} = this.props;
        // this.showLoader(true);
        if (prevProps.pickupPoint !== this.props.pickupPoint || prevProps.dropoffPoint !== this.props.dropoffPoint) {
            setDirections(null)
        }
        if(this.props.pickupPoint === '' || this.props.pickupPoint === null || this.props.pickupPoint === undefined){
            setDirections(null)
        }
        // this.showLoader(false);
    }

    handleMapMounted = (map) => {
        const { setDirections, directions } = this.props;
        const bounds = new window.google.maps.LatLngBounds();
        const DirectionsService = new window.google.maps.DirectionsService();
        // const directionsDisplay = new google.maps.DirectionsRenderer();
        // this.showLoader(true);
        if (this.props.dropoffPoint) {
            bounds.extend(this.props.pickupPoint);
            bounds.extend(this.props.dropoffPoint);
            try {
                (this.props.UIType === "public-1-driver" || this.props.UIType === "public-2-driver") ? map.fitBounds(bounds) : map.panToBounds(bounds);

                //Mark direction
                DirectionsService.route({
                    origin: new window.google.maps.LatLng(this.props.pickupPoint.lat, this.props.pickupPoint.lng),
                    destination: new window.google.maps.LatLng(this.props.dropoffPoint.lat, this.props.dropoffPoint.lng),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, async (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK && !directions) {
                        // await this.setState({ directions: result });
                        setDirections(result)
                    } else {
                        // console.error(`error fetching directions ${result}`);
                    }
                });
            } catch (e) {
                // console.log(e.message)
            }

        } else {
            setDirections(null)
        }
        // this.showLoader(false);
    }

    render() {
        const {directions} = this.props;
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={this.props.center}
                        options={{
                            streetViewControl: false,
                            fullscreenControl: false,
                            // styles: retro,
                        }}
                        ref={props.onMapMounted}
                    >
                        {/*Trip details drivers*/}
                        {this.props.driver1 ? (<Marker google={this.props.google}
                            icon={car}
                            name={'driver1'}
                            position={this.props.driver1}
                        />) : ''}

                        {this.props.driver2 ? (<Marker google={this.props.google}
                            icon={car}
                            name={'driver2'}
                            position={this.props.driver2}
                        />) : ''}

                        {/*Delivey Status drivers */}
                        {this.props.dsdriver1 ? (<Marker google={this.props.google}
                            icon={driverLocation}
                            name={'driver1'}
                            position={this.props.dsdriver1}
                            animation= {2}
                            className="geopulse"
                            title="driver1"
                        />) : ''}

                        {this.props.dsdriver2 ? (<Marker google={this.props.google}
                            icon={driverLocation}
                            name={'driver2'}
                            position={this.props.dsdriver2}
                            animation= {2}
                            className="geopulse"
                            title="driver2"
                        />) : ''}

                        {/* If DefaultPickupLocationInfo is empty then dont load the marker */}
                        {this.props.address ? (
                        <Marker google={this.props.google}
                            icon= {{
                                url:pickupMarker,
                                scaledSize : new window.google.maps.Size(37.88, 45)
                            }}
                            name={'pickup'}
                            position={this.props.pickupPoint}
                        /> ) : '' }

                        {this.props.dropoffPoint ? (<Marker google={this.props.google}
                            icon={{
                                url:dropoffMarker,
                                scaledSize : new window.google.maps.Size(37.88, 45)
                            }}
                            name={'dropoff'}
                            position={this.props.dropoffPoint}
                        />) : ''}
                        
                        {this.props.pickupPoint ? (<Marker google={this.props.google}
                            icon={{
                                url:pickupMarker,
                                scaledSize : new window.google.maps.Size(37.88, 45)
                            }}
                            name={'pickup'}
                            position={this.props.pickupPoint}
                        />) : ''}
                        {this.props.isReturnTrip ? (<Marker google={this.props.google}
                            icon={{
                                url:returnMarker,
                                scaledSize : new window.google.maps.Size(80, 45)
                            }}
                            name={'return'}
                            position={this.props.pickupPoint}
                            zIndex={10}
                        />) : ''}
                        {directions && <DirectionsRenderer directions={directions}
                            options={
                                {
                                    suppressMarkers: true,
                                    polylineOptions: {
                                        strokeColor: "#131415",
                                        strokeOpacity: 0.5,
                                        strokeWeight: 3,
                                    }
                                }
                            } />}
                    </GoogleMap>
                )
            )
        );
        let map;
        (this.props.center.lat) ? (map = <div>
            <AsyncMap
                googleMapURL={appConfig.urls.GoogleMap_URL}
                loadingElement={<div></div>}
                containerElement={<div className="map-container" style={{ height: `${this.props.height}px` }}/>}
                mapElement={<div style={{ height: `100%`, borderRadius: `10px` }} />}
                onMapMounted={this.handleMapMounted}
            />
        </div>) : (map = <div className="map-container empty-map" style={{ height: `${this.props.height}px` }} />);
        return (map)
    }
}

export default connect(
    (
        { 
            getEstimate: {
                directions
            },
            user: {
                quickAccessUser
            }
        }
    ) => 
    (
        {
            directions, 
            quickAccessUser
        }
    ),
    dispatch => (
        {
            setDirections: directions => dispatch(setDirections(directions)),
        }
    )
)(Map);

