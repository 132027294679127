import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { dateUtils } from "../../shared/utilities/dateUtils";
import calenderPng from "../../assets/images/icons/calendar.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

export class CustomDatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isActive: false,
		};
	}

	changeHandler = async (val) => {
		try {
			this.props.onChange({ target: { value: val } });
			this.setState({ isActive: false });
		} catch (e) {
			console.log(e);
		}
	};

	checkIfTodayOrTomorrow = (value) => {
		const { getDate } = dateUtils;
		const currValue = getDate(new Date(value));
		const today = getDate(new Date());
		let nextDay = new Date();
		nextDay.setDate(nextDay.getDate() + 1);
		const tomorrow = getDate(nextDay);
		if (currValue === today) return 0;
		if (currValue === tomorrow) return 1;
		return "";
	};

	render() {
		const { isActive } = this.state;
		const { value, items, workingHours, PartnerOperationHours } = this.props;
		const { getDatePickerDate, getDatePickerDateValues } = dateUtils;
		const i = this.checkIfTodayOrTomorrow(value);

		const excludedDays =
			PartnerOperationHours.length > 0
				? items.filter(
						(item) =>
							!PartnerOperationHours.find(
								(day) =>
									day.Day ===
									moment(dateUtils.getDate(new Date(item))).format("dddd")
							)
				  )
				: [];

		const checkIfDateIsExcluded = (val, curr) => {
			let addClass = "";
			if (PartnerOperationHours.length > 0) {
				let excludedDaysArray = [];
				excludedDays.map((eDaysData, i) => {
					excludedDaysArray.push(getDatePickerDate(new Date(eDaysData), i));
					return true;
				});

				if (excludedDaysArray.includes(val)) {
					addClass = " excluded";
				} else if (val === curr) {
					addClass = " active";
				} else {
					addClass = "";
				}
			} else {
				if (val === curr) {
					addClass = " active";
				} else {
					addClass = "";
				}
			}
			return addClass;
		};

		return (
			<div className="custom-date-picker">
				<div className="input-wrapper">
					<img
						alt="Calender"
						src={calenderPng}
						onClick={() => this.setState({ isActive: !isActive })}
					/>
					<input
						type="text"
						value={getDatePickerDate(new Date(value), i, workingHours)}
						onClick={() => this.setState({ isActive: !isActive })}
						onChange={() => null}
					/>
					<div
						className="icon"
						onClick={() => this.setState({ isActive: !isActive })}
					>
						<svg
							className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
						>
							<path d="M7 10l5 5 5-5z"></path>
						</svg>
					</div>
				</div>
				{isActive && (
					<>
						<Row
							className={
								"date-container" + (items.length < 6 ? " auto-dimensions" : "")
							}
						>
							<Col className="left" xs="auto">
								{items?.map((item, i) => {
									if (i < 5) {
										const val = getDatePickerDate(
											new Date(item),
											i,
											workingHours
										);
										const data = getDatePickerDateValues(new Date(item));
										const curr = getDatePickerDate(
											new Date(value),
											i,
											workingHours
										);

										return (
											<div
												key={`item-${i}`}
												className={"item" + checkIfDateIsExcluded(val, curr)}
												onClick={() => this.changeHandler(data)}
											>
												{val}
											</div>
										);
									}
								})}
							</Col>

							{items?.length > 5 && (
								<Col className="right" xs="auto" sm="7">
									<DatePicker
										open={isActive}
										selected={value}
										minDate={items && items[0]}
										onChange={(val) =>
											this.changeHandler(getDatePickerDateValues(new Date(val)))
										}
										maxDate={items && items[items.length - 1]}
										excludeDates={excludedDays.length > 0 ? excludedDays : []}
									/>
								</Col>
							)}
						</Row>
						<div
							className="backdrop"
							onClick={() => this.setState({ isActive: !isActive })}
						></div>
					</>
				)}
			</div>
		);
	}
}
