import React, { Component } from 'react';
import pickupMarker from '../../assets/images/pickup.png';
import dropoffMarker from '../../assets/images/dropoff.png';
import returnMarker from '../../assets/images/icon-return.svg';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setDirections } from '../../shared/redux/actions/getEstimate.actions';
import { driversMarker } from './DriversMarker';
class DeliveryStatusMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            isReturnMarkerVisible: false,
            geofence: [],
            markerDropPosition: null,
            pickupPoint: this.props.pickupPoint,
            dropoffPoint: this.props.dropoffPoint,
        }
    }

    /** Map instance */
    map = null;
    /** DOM container where the map canvas gets rendered. */
    mapContainer = React.createRef();

    handleMapMounted = (map) => {
        const bounds = new window.google.maps.LatLngBounds();

        bounds.extend(this.props.pickupPoint);
        bounds.extend(this.props.dropoffPoint);
        if (this.props.dropoffPoint) {
            (this.props.UIType === "public-1-driver" || this.props.UIType === "public-2-driver") ? map.fitBounds(bounds) : map.panToBounds(bounds);

            let directionsService = new window.google.maps.DirectionsService();
            let directionsRenderer = new window.google.maps.DirectionsRenderer();
            let request = {
                origin: new window.google.maps.LatLng(this.props.pickupPoint.lat, this.props.pickupPoint.lng),
                destination: new window.google.maps.LatLng(this.props.dropoffPoint.lat, this.props.dropoffPoint.lng),
                travelMode: window.google.maps.TravelMode.DRIVING,
            };
            directionsService.route(request, this.RenderCustomDirections);
            directionsRenderer.setMap(map);
        }

    }

    RenderCustomDirections = (response, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
            this.polyline = new window.google.maps.Polyline({
                path: response.routes[0]?.overview_path,
                geodesic: true,
                strokeColor: "#131415",
                strokeOpacity: 0.5,
                strokeWeight: 3,
            })

            this.polyline.setMap(this.map);
        }
    }

    buildContent = (angle, IsControlDriver) => {
        const content = document.createElement("div");

        content.classList.add("custom-marker");
        content.innerHTML = driversMarker(angle, IsControlDriver)
        return content;
    }

    getActualAngle = (dsdriver) => {
        const point1LatLng = new window.google.maps.LatLng(
            parseFloat(this.props.pickupPoint.lat),
            parseFloat(this.props.pickupPoint.lng)
        );
        const point2LatLng = new window.google.maps.LatLng(dsdriver);
        const angle = window.google.maps.geometry.spherical.computeHeading(point1LatLng, point2LatLng)
        return angle
    }

    renderAdvanceMarker = async () => {
        await window.google.maps.importLibrary("marker");

        {/*Delivey Status drivers */ }
        if (this.props.driverDetails) {
            // eslint-disable-next-line array-callback-return
            this.props.driverDetails.map((data, i) => {
                this.driver = new window.google.maps.marker.AdvancedMarkerElement({
                    position: {
                        lat: Number(
                            data?.Location.Latitude + (!data.IsControlDriver && 0.000015)
                        ),
                        lng: Number(
                            data?.Location.Longitude + (!data.IsControlDriver && 0.000007)
                        ),
                    },
                    content: this.buildContent(this.getActualAngle({ lat: data?.Location.Latitude, lng: data?.Location.Longitude }), data?.IsControlDriver),
                    title: `Driver${i + 1}`,
                })
                this.driver.setMap(this.map);
            })
        }


    }

    componentDidUpdate() {
        if (this.props.isReturnTrip && !this.state.isReturnMarkerVisible) {
            this.pickup = new window.google.maps.Marker({
                name: 'return',
                position: this.props.pickupPoint,
                icon: {
                    url: returnMarker,
                    scaledSize: new window.google.maps.Size(80, 45)
                },
            })
            this.pickup.setMap(this.map);
            this.setState({isReturnMarkerVisible : true})
        }
    }
    componentDidMount() {
        /** Create new google map. */
        this.map = new window.google.maps.Map(this.mapContainer.current, {
            center: this.props.center,
            zoom: this.props.zoom,
            options: {
                streetViewControl: false,
                fullscreenControl: false,
                // styles: retro,
            },
            mapId: window.google.maps.MapTypeId.ROADMAP,
        })
        this.handleMapMounted(this.map)
        this.renderAdvanceMarker()

        if (this.props.address) {
            this.pickup = new window.google.maps.Marker({
                name: 'pickup',
                position: this.props.pickupPoint,
                icon: {
                    url: pickupMarker,
                    scaledSize: new window.google.maps.Size(37.88, 45)
                },
            })
            this.pickup.setMap(this.map);
        }

        if (this.props.dropoffPoint) {
            this.dropoff = new window.google.maps.Marker({
                name: 'dropoff',
                position: this.props.dropoffPoint,
                icon: {
                    url: dropoffMarker,
                    scaledSize: new window.google.maps.Size(37.88, 45)
                },
            })
            this.dropoff.setMap(this.map);
        }

    }

    render() {
        return (
            <div ref={this.mapContainer} className="map-container" style={{ height: `${this.props.height}px` }} />

        );
    }
}

export default connect(
    (
        {
            getEstimate: {
                directions
            },
            user: {
                quickAccessUser
            }
        }
    ) =>
    (
        {
            directions,
            quickAccessUser
        }
    ),
    dispatch => (
        {
            setDirections: directions => dispatch(setDirections(directions)),
        }
    )
)(DeliveryStatusMap);