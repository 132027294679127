import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../shared/layouts/common/Footer";
import logo from "../assets/images/logo.png";
import linkexpired from "../assets/images/linkexpired.png";
import languageConstants from "../shared/appConfig/languageConstants";
import floorFoundLogo from "../assets/images/Floor_Found_logo.png";

const LinkExpired = () => {
	const [partnerLogo, setPartnerLogo] = useState();

	return (
		<React.Fragment>
			<main className="main-wrapper show-delivery-status" id="main-wrapper">
				{/* Header */}
				<header className="delivery-status-header">
					<Container>
						<Row>
							<Col
								className="pr-0 partner-logo d-flex align-items-center"
								md="auto"
							>
								<img src={floorFoundLogo} alt="Partner Logo" />
							</Col>
							<Col className="pl-0 powered-by align-self-center d-block">
								<img src={logo} width="93" className="logo" alt="Bungii Logo" />
							</Col>
						</Row>
					</Container>
				</header>

				<Container className="main-container detailed-information ">
					<Row>
						<Col
							xs="12"
							// md={{ span: 3, offset: 1 }}
							md="4"
							className="d-flex justify-content-center"
						>
							<img
								width="auto"
								height="248"
								src={linkexpired}
								alt="link expired"
							/>
						</Col>
						<Col xs="12" md="6" className="pl-md-3">
							<Row>
								<Col>
									<h1 className="heading">The link has expired</h1>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>The link would have expired due to following reasons:</p>
									<ul>
										<li>Your scheduled pickup window has passed.</li>
										<li>You have already scheduled the pickup.</li>
										<li>The pickup is completed or canceled.</li>
									</ul>
									<p className="support-text">
										{languageConstants.deliveryWindow.supportInfoText}{" "}
										<a href="tel:+9806555006">(980) 655-5006</a> or email{" "}
										<a href="mailto:support@bungii.com">support@bungii.com</a>
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>

				<Footer />
			</main>
		</React.Fragment>
	);
};

export default LinkExpired;
