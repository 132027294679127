import React, { Component } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import Alert from '../common/Alert';
import { handleGetReq } from '../../../shared/services/AppendHeadersService';
import { dateUtils } from '../../../shared/utilities/dateUtils';
import appConfig from '../../../shared/appConfig/appConfig';
import toasterService from '../../../shared/services/ToasterService'
import languageConstants from '../../../shared/appConfig/languageConstants';
import { getFullAddress } from '../../../shared/modules/Address';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setShowLoader } from '../../../shared/redux/actions/common.actions';

class PickupExists extends Component {
    constructor(props){
        super(props);
        this.state = {
            scheduledDateTime: '',
            pickupAddress: '',
            dropOffAddress: ''
        }
    }
    /**
     * componentDidMount()
     * This function will be called only after the first render.
     */
    componentDidMount = async () => {
        try{
            const { accessToken, setShowLoader, orderRef, tripType} = this.props;
            let pickupreqId = this.props.pickupRequestID
            let tripDetails = await handleGetReq(appConfig.urls.tripDetails, tripType > 2 ? { orderref: orderRef } : { pickuprequestid: pickupreqId }, accessToken);
            setShowLoader(true);
            if (tripDetails !== undefined && tripDetails.data.Error === null) {
                const {
                    ScheduledDateTime: scheduledDateTime,
                    PickupAddress: pickupAddress,
                    DropOffAddress: dropOffAddress,
                    PickupNote: pickupNote,
                    TimezoneIdentifier: timezoneIdentifier,
                    TimezoneDisplayName: timezoneDisplayName,
                } = tripDetails.data.Details.PickupDetails;
                let scheduledTimestamp = dateUtils.convertTimezone(scheduledDateTime, timezoneIdentifier, `ddd, ll [at] h:mm A [(${timezoneDisplayName})]`);
                this.setState({
                    scheduledDateTime: scheduledTimestamp,
                    pickupAddress: getFullAddress(pickupAddress),
                    dropOffAddress: getFullAddress(dropOffAddress),
                    pickupNote: pickupNote
                })
                setShowLoader(false)
            } else if(tripDetails.data.Error.Code === 20009){
                setShowLoader(false)
                toasterService.showCustomToasty(languageConstants.errors.invalidUser.message, "error");
                setTimeout(function(){ 
                    window.location.replace(`/`);
                }, 2000);
            }
            else {
                setShowLoader(false)
                toasterService.showCustomToasty(tripDetails.data.Error.Message, "error");
            }
            
        } catch(err){
            console.log(err)
        }
    }


    render() {
        return (
            <React.Fragment>
                <Alert showAlertModal={this.props.showAlertModal} handleCloseAlert={this.props.handleCloseAlert} showCloseIcon={true} alertType='alert-lg'>
                    <h2>{languageConstants.pickupExisits.header}</h2>
                    <p>{languageConstants.pickupExisits.description1}</p>
                    <div className="alreadyExists">
                        <label>{languageConstants.pickupExisits.label1}</label> <span>{this.state.scheduledDateTime}</span>
                        <label>{languageConstants.pickupExisits.label2} </label>
                        <span>{this.state.pickupAddress}</span>
                        <label>{languageConstants.pickupExisits.label3} </label>
                        <span>{this.state.dropOffAddress}</span>
                    </div>
                    <div className="infoText text-left">{languageConstants.pickupExisits.description2}</div>
                    <ButtonGroup>
                        <button className="btn" onClick={this.props.resetBungii}>Schedule A New Delivery</button>
                    </ButtonGroup>
                </Alert>
            </React.Fragment >
        );
    }
}

export default connect(
    (
        {
            getEstimate: {
                editPickup, pickupPoint, minDate, geofence, defaultGeofenceIndex, rerenderDropoff, pickupDate, isDuo, defaultPoint, pickupAdd, pickupAddObj, defaultAdd, loadUnloadTimeShow, defaultGeofence, pickupTime, tripType, dropoffAdd, dropoffPoint, timeZone, dropoffAddObj, nextCalculatedDate, pickupTimeValues, loadUnload, estimate, loadTime, pickupRequestID, serviceLevel, serviceName, deliveryTime, distance, pickupAddressess, orderRef
            },
            user: {
                accessToken, partnerLocationReference, quickAccessUser
            },
            common: {
                appSetting, activeTab, showLoader, formFilled, kioskMode, showPrice
            },
            deliveryDetails: {
                pickupDetails, loadingUnloadingTime, noOfDrivers, scheduledTimestamp, lastFour, paymentMethodSelected, staticFields, customFields, customerName, customerMobile, pickUpNote, specialInstructions, itemsToDeliver, orderNumber, palletData, pickupContactName, pickupContactPhone
            }
        }
    ) =>
        (
            {
                accessToken,
                showLoader,
                orderRef,
                tripType,
            }
        ),
    dispatch => (
        {
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
        }
    )
)(PickupExists);
