import { getEstimateActionTypes } from '../actions/getEstimate.actions';

const INITIAL_STATE = {
    pickupPoint: '',
    minDate: new Date(),
    geofence: [],
    defaultGeofenceIndex: null,
    pickupDate: new Date(),
    isDuo: false,
    defaultPoint: {},
    pickupAdd: '',
    pickupAddObj: '',
    defaultAdd: '',
    loadUnloadTimeShow: false,
    defaultGeofence: '',
    pickupTime: '',
    tripType: '',
    isMuscleHelpRequired : '',
    dropoffAdd: '',
    dropoffAddObj: {},
    loadTime: '',
    dropoffPoint: '',
    timeZone: '',
    estimate: null,
    mileageCap: '',
    pickupRequestID: null,
    orderRef: null,
    showCheckbox: false,
    driverHelpCarryFieldMessage: '',
    serviceLevel: null,
    serviceLevelValues: '',
    directions: '',
    deliveryTime: '',
    distance: '',
    pickupAddressess: [],
    timeSlots: [],
    pickUpTimeFirstEntryValue: '',
    selectedAddressRef: '',
    editPickupValue: false,
    isDateChanged: false,
    partnerUpchargeValue: '',
    isServiceLevelUpdated: false,
}

const getEstimateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case getEstimateActionTypes.SET_PICKUP_POINT:
            return {
                ...state,
                pickupPoint: action.payload
            }
        case getEstimateActionTypes.SET_MIN_DATE:
            return {
                ...state,
                minDate: action.payload
            }
        case getEstimateActionTypes.SET_GEOFENCE:
            return {
                ...state,
                geofence: action.payload
            }
        case getEstimateActionTypes.SET_DEFAULT_GEOFENCE_INDEX:
            return {
                ...state,
                defaultGeofenceIndex: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_DATE:
            return {
                ...state,
                pickupDate: action.payload
            }
        case getEstimateActionTypes.SET_IS_DUO:
            return {
                ...state,
                isDuo: action.payload
            }
        case getEstimateActionTypes.SET_DEFAULT_POINT:
            return {
                ...state,
                defaultPoint: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_ADD:
            return {
                ...state,
                pickupAdd: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_ADD_OBJ:
            return {
                ...state,
                pickupAddObj: action.payload
            }
        case getEstimateActionTypes.SET_DEFAULT_ADD:
            return {
                ...state,
                defaultAdd: action.payload
            }
        case getEstimateActionTypes.SET_LOAD_UNLOAD_TIME_SHOW:
            return {
                ...state,
                loadUnloadTimeShow: action.payload
            }
        case getEstimateActionTypes.SET_DEFAULT_GEOFENCE:
            return {
                ...state,
                defaultGeofence: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_TIME:
            return {
                ...state,
                pickupTime: action.payload
            }
        case getEstimateActionTypes.SET_TRIP_TYPE:
            return {
                ...state,
                tripType: action.payload
            }
        case getEstimateActionTypes.SET_IS_MUSCLE_HELP_REQUIRED:
            return {
                ...state,
                isMuscleHelpRequired: action.payload
            }
        case getEstimateActionTypes.SET_DROPOFF_ADD:
            return {
                ...state,
                dropoffAdd: action.payload
            }
        case getEstimateActionTypes.SET_DROPOFF_ADD_OBJ:
            return {
                ...state,
                dropoffAddObj: action.payload
            }
        case getEstimateActionTypes.SET_LOAD_TIME:
            return {
                ...state,
                loadTime: action.payload
            }
        case getEstimateActionTypes.SET_DROP_OFF_POINT:
            return {
                ...state,
                dropoffPoint: action.payload
            }
        case getEstimateActionTypes.SET_TIMEZONE:
            return {
                ...state,
                timeZone: action.payload
            }
        case getEstimateActionTypes.SET_ESTIMATE:
            return {
                ...state,
                estimate: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_REQUEST_ID:
            return {
                ...state,
                pickupRequestID: action.payload
            }
        case getEstimateActionTypes.SET_ORDER_REF:
            return {
                ...state,
                orderRef: action.payload
            }
        case getEstimateActionTypes.SET_SHOW_CHECKBOX:
            return {
                ...state,
                showCheckbox: action.payload
            }
        case getEstimateActionTypes.SET_DRIVER_CARRY_FIELDS:
            return {
                ...state,
                driverHelpCarryFieldMessage: action.payload
            }
        case getEstimateActionTypes.SET_SERVICE_LEVEL:
            return {
                ...state,
                serviceLevel: action.payload
            }
        case getEstimateActionTypes.SET_DIRECTIONS:
            return {
                ...state,
                directions: action.payload
            }
        case getEstimateActionTypes.SET_SERVICE_LEVEL_VALUES:
            return {
                ...state,
                serviceLevelValues: action.payload
            }
        case getEstimateActionTypes.SET_DELIVERY_TIME:
            return {
                ...state,
                deliveryTime: action.payload
            }
        case getEstimateActionTypes.SET_DISTANCE:
            return {
                ...state,
                distance: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_ADDRESSESS:
            return {
                ...state,
                pickupAddressess: action.payload
            }
        case getEstimateActionTypes.SET_TIME_SLOTS:
            return {
                ...state,
                timeSlots: action.payload
            }
        case getEstimateActionTypes.SET_PICKUP_TIME_ENTRY_FIRST_VALUE:
            return {
                ...state,
                pickUpTimeFirstEntryValue: action.payload
            }
        case getEstimateActionTypes.SET_SELECTED_ADDRESS_REF:
            return {
                ...state,
                selectedAddressRef: action.payload
            }
        case getEstimateActionTypes.SET_EDIT_PICKUP:
            return {
                ...state,
                editPickupValue: action.payload
            }
        case getEstimateActionTypes.SET_IS_DATE_CHANGED:
            return {
                ...state,
                isDateChanged: action.payload
            }
        case getEstimateActionTypes.SET_PARTNERUPCHARGE_VALUE:
            return {
                ...state,
                partnerUpchargeValue: action.payload
            }
        case getEstimateActionTypes.SET_IS_SERVICE_LEVEL_UPDATED:
            return {
                ...state,
                isServiceLevelUpdated: action.payload
            }
        case getEstimateActionTypes.RESET_DATA_ON_PAGE_CHANGE:
            return {
                ...state,
                tripType: '',
                isMuscleHelpRequired: '',
                pickupAdd: '',
                dropoffAdd: '',
                loadTime: '',
                pickupDate: new Date(),
                dropoffPoint: '',
                pickupPoint: '',
                pickupTime: '',
                serviceLevel: null,
                defaultGeofenceIndex: (action.payload.defaultGeofenceIndex > -1) ? action.payload.defaultGeofenceIndex : null,
                defaultGeofence: (action.payload.defaultGeofence) ? action.payload.defaultGeofence : '',
                timeZone: (action.payload.timeZone) ? action.payload.timeZone : '',
                estimate: null,
                pickupAddObj: '',
                dropoffAddObj: '',
                pickupRequestID: null,
                orderRef:null,
                serviceLevelValues: '',
                deliveryTime: '',
                distance: '',
                pickupAddressess: [],
                timeSlots: [],
                pickUpTimeFirstEntryValue: '',
                selectedAddressRef: '',
                editPickupValue: false,
                isDateChanged: false,
                partnerUpchargeValue: '',
                isServiceLevelUpdated: false,
            }
        case getEstimateActionTypes.RESET_DATA_ON_LOGOUT:
            return {
                ...state,
                geofence: [],
                minDate: new Date(),
                isDuo: false,
                defaultPoint: {},
                defaultAdd: '',
                loadUnloadTimeShow: false,
                mileageCap: '',
                showCheckbox: false,
                driverHelpCarryFieldMessage: '',
                directions: '',
                isDateChanged: false,
                partnerUpchargeValue: '',
                isServiceLevelUpdated: false,
            }
        default:
            return state;
    }
}

export default getEstimateReducer;