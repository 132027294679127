export const deliveryDetailsActionTypes = {
    SET_PICKUP_DETAILS: 'SET_PICKUP_DETAILS',
    SET_LOADING_UNLOADING_TIME: 'SET_LOADING_UNLOADING_TIME',
    SET_NUMBER_OF_DRIVERS: 'SET_NUMBER_OF_DRIVERS',
    SET_SCHEDULED_TIMESTAMP: 'SET_SCHEDULED_TIMESTAMP',
    SET_PAYMENT_METHOD_SELECTED: 'SET_PAYMENT_METHOD_SELECTED',
    SET_LAST_FOUR: 'SET_LAST_FOUR',
    SET_DELIVERY_INITIAL_STATE: 'SET_DELIVERY_INITIAL_STATE',
    SET_STATIC_FIELDS: 'SET_STATIC_FIELDS',
    SET_CUSTOM_FIELDS: 'SET_CUSTOM_FIELDS',
    SET_CUSTOMER_NAME: 'SET_CUSTOMER_NAME',
    SET_CUSTOMER_MOBILE: 'SET_CUSTOMER_MOBILE',
    SET_PICKUP_NOTE: 'SET_PICKUP_NOTE',
    SET_SPECIAL_INSTRUCTIONS: 'SET_SPECIAL_INSTRUCTIONS',
    SET_ITEMS_TO_DELIVER: 'SET_ITEMS_TO_DELIVER',
    SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
    SET_PALLET_DATA: 'SET_PALLET_DATA',
    SET_PICKUP_CONTACT_NAME: 'SET_PICKUP_CONTACT_NAME',
    SET_PICKUP_CONTACT_PHONE: 'SET_PICKUP_CONTACT_PHONE',
    SET_PICKUP_TOKEN: 'SET_PICKUP_TOKEN',
    SET_MULTIPLE_PHS: 'SET_MULTIPLE_PHS',
    SET_PALLET_ERROR: 'SET_PALLET_ERROR',
    SET_EXTERNALID_LABEL: 'SET_EXTERNALID_LABEL',
    SET_BACK_TO_QUOTE: 'SET_BACK_TO_QUOTE',
}

export const setPickupDetails = pickupDetails => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_DETAILS,
    payload: pickupDetails
})

export const setLoadingUnloadingTime = loadingUnloadingTime => ({
    type: deliveryDetailsActionTypes.SET_LOADING_UNLOADING_TIME,
    payload: loadingUnloadingTime
})

export const setNoOfDrivers = noOfDrivers => ({
    type: deliveryDetailsActionTypes.SET_NUMBER_OF_DRIVERS,
    payload: noOfDrivers
})

export const setScheduledTimestamp = scheduledTimestamp => ({
    type: deliveryDetailsActionTypes.SET_SCHEDULED_TIMESTAMP,
    payload: scheduledTimestamp
})

export const setPaymentMethodSelected = paymentMethodSelected => ({
    type: deliveryDetailsActionTypes.SET_PAYMENT_METHOD_SELECTED,
    payload: paymentMethodSelected
})

export const setLastFour = lastFour => ({
    type: deliveryDetailsActionTypes.SET_LAST_FOUR,
    payload: lastFour
})

export const setDeliveryDetailsInitialState = newValues => ({
    type: deliveryDetailsActionTypes.SET_DELIVERY_INITIAL_STATE,
    payload: newValues
});

export const setStaticFields = staticFields => ({
    type: deliveryDetailsActionTypes.SET_STATIC_FIELDS,
    payload: staticFields
})

export const setCustomFields = customFields => ({
    type: deliveryDetailsActionTypes.SET_CUSTOM_FIELDS,
    payload: customFields
});

export const setCustomerName = customerName => ({
    type: deliveryDetailsActionTypes.SET_CUSTOMER_NAME,
    payload: customerName
});

export const setCustomerMobile = customerMobile => ({
    type: deliveryDetailsActionTypes.SET_CUSTOMER_MOBILE,
    payload: customerMobile
});

export const setPickUpNote = pickUpNote => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_NOTE,
    payload: pickUpNote
});

export const setSpecialInstructions = specialInstructions => ({
    type: deliveryDetailsActionTypes.SET_SPECIAL_INSTRUCTIONS,
    payload: specialInstructions
});

export const setItemsToDeliver = itemsToDeliver => ({
    type: deliveryDetailsActionTypes.SET_ITEMS_TO_DELIVER,
    payload: itemsToDeliver
});

export const setOrderNumber = orderNumber => ({
    type: deliveryDetailsActionTypes.SET_ORDER_NUMBER,
    payload: orderNumber
});

export const setExternalIdLabel = externalIdLabel => ({
    type: deliveryDetailsActionTypes.SET_EXTERNALID_LABEL,
    payload: externalIdLabel
});

export const setPalletData = palletData => ({
    type: deliveryDetailsActionTypes.SET_PALLET_DATA,
    payload: palletData
});

export const setPickupContactName = pickupContactName => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_CONTACT_NAME,
    payload: pickupContactName
});

export const setPickupContactPhone = pickupContactPhone => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_CONTACT_PHONE,
    payload: pickupContactPhone
});

export const setPickupToken = pickupToken => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_TOKEN,
    payload: pickupToken
})

export const setMultiplePhs = customerMobiles => ({
    type: deliveryDetailsActionTypes.SET_MULTIPLE_PHS,
    payload: customerMobiles
});
export const setPalletErrors = palletErrors => ({
    type: deliveryDetailsActionTypes.SET_PALLET_ERROR,
    payload: palletErrors
});
export const setBackToQuote = backToQuote => ({
    type: deliveryDetailsActionTypes.SET_BACK_TO_QUOTE,
    payload: backToQuote
});

