import { deliveryDetailsActionTypes } from '../actions/deliveryDetails.actions';

const INITIAL_STATE = {
    pickupDetails: '',
    loadingUnloadingTime: '',
    noOfDrivers: '',
    scheduledTimestamp: '',
    paymentMethodSelected: '',
    lastFour: '',
    staticFields: '',
    customFields: '',
    customerName: '',
    customerMobile: '',
    pickUpNote: '',
    specialInstructions: '',
    itemsToDeliver: [],
    orderNumber: '',
    externalIdLabel: '',
    palletData: [],
    pickupContactName: '',
    pickupContactPhone: '',
    pickupToken: '',
    multiplePhoneNumbers: [],
    palletErrors: [
        {
            palletError: ""
        },
        {
            palletError: ""
        }
    ],
    backToQuote: false,
}

const deliveryDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case deliveryDetailsActionTypes.SET_PICKUP_DETAILS:
            return {
                ...state,
                pickupDetails: action.payload
            }
        case deliveryDetailsActionTypes.SET_LOADING_UNLOADING_TIME:
            return {
                ...state,
                loadingUnloadingTime: action.payload
            }
        case deliveryDetailsActionTypes.SET_NUMBER_OF_DRIVERS:
            return {
                ...state,
                noOfDrivers: action.payload
            }
        case deliveryDetailsActionTypes.SET_SCHEDULED_TIMESTAMP:
            return {
                ...state,
                scheduledTimestamp: action.payload
            }
        case deliveryDetailsActionTypes.SET_PAYMENT_METHOD_SELECTED:
            return {
                ...state,
                paymentMethodSelected: action.payload
            }
        case deliveryDetailsActionTypes.SET_LAST_FOUR:
            return {
                ...state,
                lastFour: action.payload
            }
        case deliveryDetailsActionTypes.SET_STATIC_FIELDS:
            return {
                ...state,
                staticFields: action.payload
            }
        case deliveryDetailsActionTypes.SET_CUSTOM_FIELDS:
            return {
                ...state,
                customFields: action.payload
            }
        case deliveryDetailsActionTypes.SET_CUSTOMER_NAME:
            return {
                ...state,
                customerName: action.payload
            }
        case deliveryDetailsActionTypes.SET_CUSTOMER_MOBILE:
            return {
                ...state,
                customerMobile: action.payload
            }
        case deliveryDetailsActionTypes.SET_PICKUP_NOTE:
            return {
                ...state,
                pickUpNote: action.payload
            }
        case deliveryDetailsActionTypes.SET_SPECIAL_INSTRUCTIONS:
            return {
                ...state,
                specialInstructions: action.payload
            }
        case deliveryDetailsActionTypes.SET_ITEMS_TO_DELIVER:
            return {
                ...state,
                itemsToDeliver: action.payload
            }
        case deliveryDetailsActionTypes.SET_ORDER_NUMBER:
            return {
                ...state,
                orderNumber: action.payload
            }
        case deliveryDetailsActionTypes.SET_EXTERNALID_LABEL:
            return {
                ...state,
                externalIdLabel: action.payload
            }
        case deliveryDetailsActionTypes.SET_PALLET_DATA:
            return {
                ...state,
                palletData: action.payload
            }
        case deliveryDetailsActionTypes.SET_PICKUP_CONTACT_NAME:
            return {
                ...state,
                pickupContactName: action.payload
            }
        case deliveryDetailsActionTypes.SET_PICKUP_CONTACT_PHONE:
            return {
                ...state,
                pickupContactPhone: action.payload
            }
        case deliveryDetailsActionTypes.SET_PICKUP_TOKEN:
            return {
                ...state,
                pickupToken: action.payload
            }
        case deliveryDetailsActionTypes.SET_MULTIPLE_PHS:
            return {
                ...state,
                multiplePhoneNumbers: action.payload
            }
        case deliveryDetailsActionTypes.SET_BACK_TO_QUOTE:
        return {
            ...state,
            backToQuote: action.payload
        }
        case deliveryDetailsActionTypes.SET_DELIVERY_INITIAL_STATE:
            return {
                pickupDetails: '',
                loadingUnloadingTime: '',
                noOfDrivers: '',
                scheduledTimestamp: '',
                paymentMethodSelected: '',
                lastFour: '',
                staticFields: '',
                customFields: '',
                customerName: '',
                customerMobile: '',
                pickUpNote: '',
                specialInstructions: '',
                itemsToDeliver: [],
                orderNumber: '',
                externalIdLabel: '',
                palletData: [],
                pickupContactName: '',
                pickupContactPhone: '',
                pickupToken: '',
                multiplePhoneNumbers: [],
                palletErrors: [
                    {
                        palletError: ""
                    },
                    {
                        palletError: ""
                    }
                ],
                backToQuote: false,
            }
        case deliveryDetailsActionTypes.SET_PALLET_ERROR:
            return {
                ...state,
                palletErrors: action.payload
            }
        default:
            return state;
    }
}

export default deliveryDetailsReducer;

