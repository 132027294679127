import moment from 'moment-timezone';
import { dateUtils } from '../utilities/dateUtils';

/* Redux Import Start */
import {store} from '../redux/store';
import { getWorkingHours } from './getWorkingHours';
import globalConstants from '../../shared/appConfig/globalConstants';
/* Redux Import End */

/**
 * timeInsideWorkingHours()
 * This function is used to check if current time is inside working hours or not
 */
const checkIfTimeInsideBungiiHours = () => {
    const { timeZone } = store.getState().getEstimate;
    const {appSetting} = store.getState().common;
    const {ScheduledPickupFrom, ScheduledPickupTo} = appSetting.BungiiSettings;
    const min = ScheduledPickupFrom;
    const max = ScheduledPickupTo;

    const minWorkingHours =  (min / 3600000).toFixed(1); // Converting milisecs to hours
    const maxWorkingHours =  (max / 3600000).toFixed(1); // Converting milisecs to hours
    let currentTime = moment.utc().tz(timeZone).format(globalConstants.HourFormat2).split(':');
    currentTime = (((currentTime[0] * 3600000) + (currentTime[1] * 60000) + (currentTime[2] * 1000)) / 3600000).toFixed(1);
    
    return {
        isInsideWorkingHours: ((Number(minWorkingHours) < Number(currentTime))  && (Number(currentTime) < Number(maxWorkingHours))),
        beforeStartHours: Number(currentTime) < Number(minWorkingHours),
    }; 
}

/**
 * getLastestSlotTimeWithBuffer()
 * This function is used to add first slot time after adding lead time
 */
const getLastestSlotTimeWithBuffer = (bufferTime, currentDate, isFirstSlot) => {
    const { appSetting } = store.getState().common;
    const { ScheduledPickupFrom, ScheduledPickupTo } = appSetting.BungiiSettings;

    const startTime = dateUtils.millisecsToTime(ScheduledPickupFrom);
    const WorkingHrsCnt = (ScheduledPickupTo - ScheduledPickupFrom)/1000/3600;
    let bufferCnt = bufferTime / 1000 / 3600;

    if(bufferCnt > WorkingHrsCnt){  // Lead time greater than working hours
        let daysToAdd = 0;
        let timeToAdd = null;
        while(bufferCnt > 0){
            const prevBuffer = bufferCnt;
            bufferCnt = bufferCnt - WorkingHrsCnt;
            const hasNextDay = bufferCnt > 0;
            if(hasNextDay){
                daysToAdd++;
                continue;
            }
            timeToAdd = prevBuffer;
        }
        
        const finalDate = moment(`${currentDate} ${startTime}`).add(daysToAdd, 'days').add(timeToAdd, "hours").format(globalConstants.DateTimeFormat);
        return (isFirstSlot) ? {'currentDate': currentDate,'currentTime': startTime.trim(),'bufferTime': new Date(finalDate).getTime() / 1000} : new Date(finalDate).getTime() / 1000;
    }
    return (isFirstSlot) ? {'currentDate': currentDate,'currentTime': startTime.trim(),'bufferTime':(new Date(`${currentDate} ${startTime}`).getTime() + (bufferTime))/1000} : (new Date(`${currentDate} ${startTime}`).getTime() + (bufferTime))/1000;
}

/**
 * getStartTimeWithLeadTime()
 * This function is used to get slot time wrt current time and working hours
 */
export const getStartTimeWithLeadTime = (isFirstSlot) => {
    const { tripType, timeZone, defaultGeofence, isMuscleHelpRequired } = store.getState().getEstimate;
    const { appSetting } = store.getState().common;
    const { ScheduledPickupTo } = appSetting.BungiiSettings;
    const tripConfigurableSettings = appSetting.PartnerLocationSettings.TripConfigurableSettings;
    const bufferTime = 
        (tripType === 1 && isMuscleHelpRequired!==true) 
            ? (tripConfigurableSettings && tripConfigurableSettings.SoloEarliestScheduledTime) 
                ? tripConfigurableSettings.SoloEarliestScheduledTime 
                : defaultGeofence.SoloEarliestScheduledTime 
            : (tripConfigurableSettings && tripConfigurableSettings.DuoEarliestScheduledTime) 
                ? tripConfigurableSettings.DuoEarliestScheduledTime 
                : defaultGeofence.DuoEarliestScheduledTime;

    // Get current Date and current Time in Current TIMEZONE ( EPOCH time value )
    const currentDateTimeInTimeZone = new Date().toLocaleString('en-GB', { timeZone: timeZone }).split(",");
    const currentDate = dateUtils.getYYMMDDSafari(currentDateTimeInTimeZone[0]);
    const currentTime = currentDateTimeInTimeZone[1];
    // Check if current time is outside working hours
    const {isInsideWorkingHours, beforeStartHours} = checkIfTimeInsideBungiiHours();
     
    // Current time is not inside working hours
    if(!isInsideWorkingHours){
        if(beforeStartHours){
            return getLastestSlotTimeWithBuffer(bufferTime, currentDate, isFirstSlot);
        }

        const tomorrowDate = moment(currentDate,globalConstants.DateFormat).add('days', 1).format(globalConstants.DateFormat2);

        return getLastestSlotTimeWithBuffer(bufferTime, tomorrowDate, isFirstSlot);
    }

    // Current time is inside working hours
    const timeArray = currentTime.split(':');
    const timeInMillisec = dateUtils.timeToMilliSeconds(timeArray[0],timeArray[1],0);
    const remainingWorkingHoursToday = ScheduledPickupTo - timeInMillisec; // Remaining working hours for current date
    const remainingBufferTime = bufferTime - remainingWorkingHoursToday; // Remaining lead time after adding it to current date
    if(remainingBufferTime > 0){ // If bufferTime exceeds working hours for current date
        const tomorrowDate = moment(currentDate,globalConstants.DateFormat).add('days', 1).format(globalConstants.DateFormat2);
        return getLastestSlotTimeWithBuffer(remainingBufferTime, tomorrowDate, isFirstSlot);
    }
    // bufferTime does not exceed working hours for current date
    return (isFirstSlot) ? { 'currentDate': currentDate, 'currentTime': currentTime.trim(), 'bufferTime': (new Date(currentDate + currentTime).getTime() + (bufferTime)) / 1000 } : (new Date(currentDate + currentTime).getTime() + (bufferTime)) / 1000;
}

export const getFirstSlotTime = () => {
    const { timeZone } = store.getState().getEstimate;
    const { appSetting } = store.getState().common;
    const {WorkingHoursStart, WorkingHoursEnd} = getWorkingHours();
    const min = WorkingHoursStart;
    const max = WorkingHoursEnd;
    const interval = appSetting.BungiiSettings.SchedulingBlockPeriod;
    let firstSlot = null;
    const pickupDateVal = getStartTimeWithLeadTime(true);
    for (let i = min; i <= max; i = i + interval) {        
        // Get slot Time ( EPOCH time value )
        const time = moment(i).utc().format(globalConstants.HourFormat2);
        const slotTime = dateUtils.getTimeStampSafari(`${pickupDateVal.currentDate} ${time}`);
        
        // Display all time slots greater than ( current Time + Buffer Time ) where date is pickup date
        if (slotTime > pickupDateVal.bufferTime.toFixed(0)) {
            firstSlot = slotTime;
            break;
        }
    }
    if(!firstSlot){
        return moment(pickupDateVal.currentDate,globalConstants.DateFormat).add('days', 1).format(globalConstants.DateTimeFormat);
    }
    
    const dateToPass = (pickupDateVal.currentDate).split('/').join("-")
    return new Date(moment(dateToPass+"T"+pickupDateVal.currentTime).tz(timeZone).format(globalConstants.DateTimeFormat))
}