import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import clearAppsettingData from "../shared/helpers/clearAppsettingData";
import languageConstants from "../shared/appConfig/languageConstants";
import ReactGA from "react-ga";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setActiveTab,
	setCommonInitialState,
} from "../shared/redux/actions/common.actions";
import { resetDataOnPageChange } from "../shared/redux/actions/getEstimate.actions";
import { setDeliveryDetailsInitialState } from "../shared/redux/actions/deliveryDetails.actions";

/* Redux Import End */

class Success extends Component {
	constructor(props) {
		super(props);
		this.state = {
			category: "",
			action: "",
			label: "",
			whatsNeededData:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0],
		};
	}

	/**
	 * sendEvent()
	 * This function is used to send trigger event to GA
	 */
	sendEvent = async (val) => {
		await this.setState({
			category: `CTA ${val} Button`,
			action: "Click",
			label: val,
		});
		ReactGA.event(this.state);
	};

	/**
	 * newBungii()
	 * Tis function is called on click of newBungii button.
	 */
	newBungii = async () => {
		try {
			const {
				setActiveTab,
				setDeliveryDetailsInitialState,
				setCommonInitialState,
				resetDataOnPageChange,
			} = this.props;
			let data = {
				defaultGeofenceIndex: null,
				defaultGeofence: "",
				timeZone: "",
			};

			/**
			 * Set redux state
			 */
			setDeliveryDetailsInitialState();
			resetDataOnPageChange(data);
			setCommonInitialState();

			/**
			 * Clear appSetting data
			 */
			clearAppsettingData();

			setActiveTab("getEstimate");
			this.props.history.push("/request-bungii");
		} catch (err) {
			console.log(err);
		}
	};

	render() {
		const {
			pickupAdd,
			dropoffAdd,
			noOfDrivers,
			scheduledTimestamp,
			estimate,
			paymentMethodSelected,
			lastFour,
			appSetting,
			showPrice,
			orderNumber,
			pickupToken,
			partnerUpchargeValue,
			tripType,
			externalIdLabel,
			pickupRequestID,
			orderRef,
		} = this.props;
		const { whatsNeededData } = this.state;
		return (
			<React.Fragment>
				<h1>Done!</h1>
				<Container>
					<Row>
						<Col md={{ span: 10, offset: 1 }} className="px-0">
							<h1 className="heading">Your delivery has been scheduled.</h1>
						</Col>
					</Row>
					<Row>
						<Col
							xs="12"
							md={{ span: 4, offset: 1 }}
							className="d-none d-sm-block"
						>
							<section className="summary-container success-trip-details">
								<div className="pickup-icon">
									<div className="content">
										<label>Pickup Address:</label>
										<p>{pickupAdd}</p>
									</div>
								</div>
								<div className="delivery-icon">
									<div className="content">
										<label>Delivery Address:</label>
										<p>{dropoffAdd}</p>
									</div>
								</div>
							</section>
						</Col>
						<Col xs="12" md="6" className="px-0 pl-md-3">
							<section className="summary-container grey">
								{tripType <= 2 && (<div>
									<label>Tracking ID:</label>
									<p>{pickupToken}</p>
								</div>)}
								<div>
									<label>What’s needed:</label>
									{whatsNeededData.UIType !== 5 && (
										<p>
											{this.props.isMuscleHelpRequired ? "Large item(s) - 2 drivers"
												: whatsNeededData.UIType === 6 ?
													(tripType + " Pallet" + (tripType > 1 ? "s" : ""))
													: (noOfDrivers === 1
														? whatsNeededData.SoloSummarysectionvalue
														: whatsNeededData.DuoSummarysectionvalue)}
										</p>
									)}
									{whatsNeededData.UIType === 5 && (
										<p>{whatsNeededData.Summarysectionvalue}</p>
									)}
								</div>
								{whatsNeededData.UIType === 6 ? (
									<div>
										<label>{externalIdLabel ? externalIdLabel :"Order Number"}:</label>
										<p>{orderNumber ? orderNumber : tripType > 2 ? orderRef : pickupRequestID }</p>
									</div>
								): orderNumber && (
									<div>
										<label>Order Number:</label>
										<p>{orderNumber}</p>
									</div>
								)}
								<div>
									<label>Pickup date & Time:</label>
									<p>{scheduledTimestamp}</p>
								</div>
								<div>
									<label>Payment:</label>
									{paymentMethodSelected === "MI" && (
										<p>This will be included on your invoice.</p>
									)}
									{paymentMethodSelected === "CC" && (
										<p>
											Customer Card &emsp;&bull;&bull;&bull;&bull;
											&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{" "}
											{lastFour}
										</p>
									)}
									{paymentMethodSelected === "PC" && <p>Partner Pay</p>}
								</div>
								<div>
									<h2 className="estimate-label">
										{appSetting.PartnerLocationSettings.PartnerHasServiceLevels
											? "Delivery "
											: "Estimated "}
										Cost
										<span>
											<strong>
												{showPrice
													? appSetting.PartnerLocationSettings
															?.EstimatePageFields[0]?.IsPartnerUpcharge
														? partnerUpchargeValue.split("-")[0]
														: estimate
													: languageConstants.defaultPrice.bestBuy}
											</strong>
										</span>
									</h2>
								</div>
							</section>
						</Col>
					</Row>
					<Row>
						<Col
							md={{ span: 10, offset: 1 }}
							className="text-center text-md-left"
						>
							<button className="btn mt-4" onClick={this.newBungii}>
								New Bungii
							</button>
							{tripType > 2 && <p className="p-t-10"><b>This scheduled delivery will be visible on your Track Deliveries page in 2-3 minutes.</b></p>}
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		);
	}
}

export default connect(
	({
		getEstimate: { pickupAdd, dropoffAdd, estimate, partnerUpchargeValue, tripType,orderRef,pickupRequestID,isMuscleHelpRequired },
		deliveryDetails: {
			noOfDrivers,
			scheduledTimestamp,
			paymentMethodSelected,
			lastFour,
			orderNumber,
			pickupToken,
			externalIdLabel,
		},
		common: { appSetting, activeTab, showPrice },
	}) => ({
		activeTab,
		appSetting,
		noOfDrivers,
		scheduledTimestamp,
		pickupAdd,
		dropoffAdd,
		estimate,
		paymentMethodSelected,
		lastFour,
		showPrice,
		orderNumber,
		pickupToken,
		partnerUpchargeValue,
		tripType,
		externalIdLabel,
		pickupRequestID,
		orderRef,
		isMuscleHelpRequired,
	}),
	(dispatch) => ({
		setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
		setDeliveryDetailsInitialState: (newValues1) =>
			dispatch(setDeliveryDetailsInitialState(newValues1)),
		setCommonInitialState: (commonInitialState) =>
			dispatch(setCommonInitialState(commonInitialState)),
		resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
	})
)(Success);
