export const getEstimateActionTypes = {
    SET_PICKUP_POINT: 'SET_PICKUP_POINT',
    SET_MIN_DATE: 'SET_MIN_DATE',
    SET_GEOFENCE: 'SET_GEOFENCE',
    SET_DEFAULT_GEOFENCE_INDEX: 'SET_DEFAULT_GEOFENCE_INDEX',
    SET_PICKUP_DATE: 'SET_PICKUP_DATE',
    SET_IS_DUO: 'SET_IS_DUO',
    SET_DEFAULT_POINT: 'SET_DEFAULT_POINT',
    SET_PICKUP_ADD: 'SET_PICKUP_ADD',
    SET_PICKUP_ADD_OBJ: 'SET_PICKUP_ADD_OBJ',
    SET_DEFAULT_ADD: 'SET_DEFAULT_ADD',
    SET_LOAD_UNLOAD_TIME_SHOW: 'SET_LOAD_UNLOAD_TIME_SHOW',
    SET_DEFAULT_GEOFENCE: 'SET_DEFAULT_GEOFENCE',
    SET_PICKUP_TIME: 'SET_PICKUP_TIME',
    SET_TRIP_TYPE: 'SET_TRIP_TYPE',
    SET_IS_MUSCLE_HELP_REQUIRED : 'SET_IS_MUSCLE_HELP_REQUIRED',
    SET_DROPOFF_ADD: 'SET_DROPOFF_ADD',
    SET_DROPOFF_ADD_OBJ: 'SET_DROPOFF_ADD_OBJ',
    SET_LOAD_TIME: 'SET_LOAD_TIME',
    SET_DROP_OFF_POINT: 'SET_DROP_OFF_POINT',
    SET_TIMEZONE: 'SET_TIMEZONE',
    SET_ESTIMATE: 'SET_ESTIMATE',
    SET_PICKUP_REQUEST_ID: 'SET_PICKUP_REQUEST_ID',
    SET_SHOW_CHECKBOX: 'SET_SHOW_CHECKBOX',
    SET_DRIVER_CARRY_FIELDS: 'SET_DRIVER_CARRY_FIELDS',
    SET_SERVICE_LEVEL: 'SET_SERVICE_LEVEL',
    SET_SERVICE_LEVEL_VALUES: 'SET_SERVICE_LEVEL_VALUES',
    SET_DIRECTIONS: 'SET_DIRECTIONS',
    RESET_DATA_ON_PAGE_CHANGE: 'RESET_DATA_ON_PAGE_CHANGE',
    RESET_DATA_ON_LOGOUT: 'RESET_DATA_ON_LOGOUT',
    SET_DELIVERY_TIME: 'SET_DELIVERY_TIME',
    SET_DISTANCE: 'SET_DISTANCE',
    SET_PICKUP_ADDRESSESS: 'SET_PICKUP_ADDRESSESS',
    SET_TIME_SLOTS: 'SET_TIME_SLOTS',
    SET_PICKUP_TIME_ENTRY_FIRST_VALUE: 'SET_PICKUP_TIME_ENTRY_FIRST_VALUE',
    SET_SELECTED_ADDRESS_REF: 'SET_SELECTED_ADDRESS_REF',
    SET_EDIT_PICKUP: 'SET_EDIT_PICKUP',
    SET_IS_DATE_CHANGED: 'SET_IS_DATE_CHANGED',
    SET_PARTNERUPCHARGE_VALUE: 'SET_PARTNERUPCHARGE_VALUE',
    SET_ORDER_REF: 'SET_ORDER_REF',
    SET_IS_SERVICE_LEVEL_UPDATED: 'SET_IS_SERVICE_LEVEL_UPDATED',
}

export const setPickupPoint = pickupPoint => ({
    type: getEstimateActionTypes.SET_PICKUP_POINT,
    payload: pickupPoint
})

export const setMinDate = minDate => ({
    type: getEstimateActionTypes.SET_MIN_DATE,
    payload: minDate
})

export const setGeofence = geofence => ({
    type: getEstimateActionTypes.SET_GEOFENCE,
    payload: geofence
})

export const setDefaultGeofenceIndex = defaultGeofenceIndex => ({
    type: getEstimateActionTypes.SET_DEFAULT_GEOFENCE_INDEX,
    payload: defaultGeofenceIndex
})

export const setPickupDate = pickupDate => ({
    type: getEstimateActionTypes.SET_PICKUP_DATE,
    payload: pickupDate
})

export const setIsDuo = isDuo => ({
    type: getEstimateActionTypes.SET_IS_DUO,
    payload: isDuo
})

export const setDefaultPoint = defaultPoint => ({
    type: getEstimateActionTypes.SET_DEFAULT_POINT,
    payload: defaultPoint
})

export const setPickupAdd = pickupAdd => ({
    type: getEstimateActionTypes.SET_PICKUP_ADD,
    payload: pickupAdd
})

export const setPickupAddObj = pickupAddObj => ({
    type: getEstimateActionTypes.SET_PICKUP_ADD_OBJ,
    payload: pickupAddObj
})

export const setDefaultAdd = defaultAdd => ({
    type: getEstimateActionTypes.SET_DEFAULT_ADD,
    payload: defaultAdd
})

export const setLoadUnloadTimeShow = loadUnloadTimeShow => ({
    type: getEstimateActionTypes.SET_LOAD_UNLOAD_TIME_SHOW,
    payload: loadUnloadTimeShow
})

export const setMaxDate = maxDate => ({
    type: getEstimateActionTypes.SET_MAX_DATE,
    payload: maxDate
})

export const setDefaultGeofence = defaultGeofence => ({
    type: getEstimateActionTypes.SET_DEFAULT_GEOFENCE,
    payload: defaultGeofence
})

export const setPckupTime = pickupTime => ({
    type: getEstimateActionTypes.SET_PICKUP_TIME,
    payload: pickupTime
})

export const setTripTypeValue = tripType => ({
    type: getEstimateActionTypes.SET_TRIP_TYPE,
    payload: tripType
})

export const setIsMuscleHelpRequired = isRequired => ({
    type: getEstimateActionTypes.SET_IS_MUSCLE_HELP_REQUIRED,
    payload: isRequired
})

export const setDropoffAdd = dropoffAdd => ({
    type: getEstimateActionTypes.SET_DROPOFF_ADD,
    payload: dropoffAdd
})

export const setDropoffAddObj = dropoffAddObj => ({
    type: getEstimateActionTypes.SET_DROPOFF_ADD_OBJ,
    payload: dropoffAddObj
})

export const setLoadTimeValue = loadTime => ({
    type: getEstimateActionTypes.SET_LOAD_TIME,
    payload: loadTime
})

export const setDropoffPoint = dropoffPoint => ({
    type: getEstimateActionTypes.SET_DROP_OFF_POINT,
    payload: dropoffPoint
})

export const setTimeZone = timeZone => ({
    type: getEstimateActionTypes.SET_TIMEZONE,
    payload: timeZone
})

export const setEstimate = estimate => ({
    type: getEstimateActionTypes.SET_ESTIMATE,
    payload: estimate
})

export const setPickupRequestID = pickupRequestID => ({
    type: getEstimateActionTypes.SET_PICKUP_REQUEST_ID,
    payload: pickupRequestID
})

export const setOrderRef = orderRef => ({
    type: getEstimateActionTypes.SET_ORDER_REF,
    payload: orderRef
})

export const setShowCheckbox = showCheckbox => ({
    type: getEstimateActionTypes.SET_SHOW_CHECKBOX,
    payload: showCheckbox
});

export const setDriverHelpCarryFieldMessage = driverHelpCarryFieldMessage => ({
    type: getEstimateActionTypes.SET_DRIVER_CARRY_FIELDS,
    payload: driverHelpCarryFieldMessage
});

export const setServiceLevel = serviceLevel => ({
    type: getEstimateActionTypes.SET_SERVICE_LEVEL,
    payload: serviceLevel
});

export const setDirections = directions => ({
    type: getEstimateActionTypes.SET_DIRECTIONS,
    payload: directions
})

export const resetDataOnPageChange = data => ({
    type: getEstimateActionTypes.RESET_DATA_ON_PAGE_CHANGE,
    payload: data
});

export const resetDataOnLogout = data => ({
    type: getEstimateActionTypes.RESET_DATA_ON_LOGOUT,
    payload: data
});

export const setServiceLevelValues = serviceLevelValues => ({
    type: getEstimateActionTypes.SET_SERVICE_LEVEL_VALUES,
    payload: serviceLevelValues
});

export const setDeliveryTime = deliveryTime => ({
    type: getEstimateActionTypes.SET_DELIVERY_TIME,
    payload: deliveryTime
});

export const setDistance = distance => ({
    type: getEstimateActionTypes.SET_DISTANCE,
    payload: distance
});

export const setPickupAddressess = pickupAddressess => ({
    type: getEstimateActionTypes.SET_PICKUP_ADDRESSESS,
    payload: pickupAddressess
});

export const setTimeSlots = timeSlots => ({
    type: getEstimateActionTypes.SET_TIME_SLOTS,
    payload: timeSlots
})

export const setPickUpTimeFirstEntryValue = pickUpTimeFirstEntryValue => ({
    type: getEstimateActionTypes.SET_PICKUP_TIME_ENTRY_FIRST_VALUE,
    payload: pickUpTimeFirstEntryValue
})

export const setSelectedAddressRef = selectedAddressRef => ({
    type: getEstimateActionTypes.SET_SELECTED_ADDRESS_REF,
    payload: selectedAddressRef
})

export const setEditPickup = editPickupValue => ({
    type: getEstimateActionTypes.SET_EDIT_PICKUP,
    payload: editPickupValue
})

export const setIsDateChanged = isDateChanged => ({
    type: getEstimateActionTypes.SET_IS_DATE_CHANGED,
    payload: isDateChanged
})

export const setPartnerUpchargeValue = partnerUpchargeValue => ({
    type: getEstimateActionTypes.SET_PARTNERUPCHARGE_VALUE,
    payload: partnerUpchargeValue
})

export const setIsServiceLevelUpdated = isServiceLevelUpdated => ({
    type: getEstimateActionTypes.SET_IS_SERVICE_LEVEL_UPDATED,
    payload: isServiceLevelUpdated
});

